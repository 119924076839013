import { produce } from 'immer';

import types from './types';


const account = (state = {}, action) => produce(state, draft => {
	const { meta, payload, type } = action;
	switch (type) {

		case types.ACCOUNT.SUCCESS: {
			//const { name } = meta;
			let name = 'schwab';

			draft[name] = draft[name] || {};
			Object.assign(draft[name], {}, payload);
			return;
		}

		case types.ACCOUNT_INSTRUMENTS.SUCCESS: {
			const { name } = meta;

			draft[name] = draft[name] || {};
			draft[name].instruments = draft[name].instruments || {};

			if (payload && payload.length) {
				payload.forEach((instruments) => {
					draft[name].instruments[instruments.name] = instruments;
				});
			}
			return;
		}

		case types.ACCOUNT_POSITIONS.SUCCESS: {
			if (payload) {
				draft.positions = payload.positions;
			}
			return;
		}

		default: return state;
	}
});

const reducer = account;

export default reducer;

