import { all, call, delay, takeLatest, put, fork, select } from 'redux-saga/effects';
import { Auth } from 'aws-amplify';
// import { push, getLocation } from 'connected-react-router';

import actions from './actions';
import types from './types';
import {api} from "@@Utils";
import config from "@@Config";
import {RSAA} from "redux-api-middleware";
import { redirect } from 'react-router';
import axios from 'axios';


/**
 Workers
 */
function* getSession() {
	try {
		console.log('getSession');
		const session = yield call([Auth, 'currentSession']);
		console.log('getSession', session);
		yield put(actions.getSession.success(session));
		const state = yield select();
		/*const { pathname } = getLocation(state);

		if (pathname === '/auth/sign-in') {
			yield put(push('/'));
		}*/
	} catch (err) {
		console.warn(err);
		yield put(actions.getSession.failure(err));
		// yield put(push('/'));
		return redirect('/');
	}
}

function* signIn(action) {
	try {
		const { username, password, newPassword } = action.payload;
		const cognitoUser = yield call([Auth, 'signIn'], username, password);

		if (cognitoUser.challengeName === 'SMS_MFA' || cognitoUser.challengeName === 'SOFTWARE_TOKEN_MFA') {
			// Need to get the code from the UI inputs and then trigger the following function with a button click
			/* const code = getCodeFromUserInput();
			// If MFA is enabled, sign-in should be confirmed with the confirmation code
			const loggedUser = await Auth.confirmSignIn(
				cognitoUser,   // Return object from Auth.signIn()
				code,   // Confirmation code
				mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
			); */
		} else if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
			// const { requiredAttributes } = cognitoUser.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
			// Need to get the new password and required attributes from the UI inputs
			// and then trigger the following function with a button click
			// For example, the email and phone_number are required attributes
			// const { username, email, phone_number } = getInfoFromUserInput();
			/* const loggedUser = await Auth.completeNewPassword(
				cognitoUser,              // the Cognito User Object
				password,       // the new password
				// OPTIONAL, the required attributes
				{
					//email,
					//phone_number,
				}
			); */
		} else if (cognitoUser.challengeName === 'MFA_SETUP') {
			// This happens when the MFA method is TOTP
			// The user needs to setup the TOTP before using it
			// More info please check the Enabling MFA part
			// await Auth.setupTOTP(cognitoUser);
		} else {
			// await dispatch(getAWSCredentials());

			// The user directly signs in
			setTimeout(async () => {

				/* const serverSideEncryption = 'aws:kms';
				const SSECustomerAlgorithm = 'AES256';
				const SSECustomerKey = 'e2f2c546-64ea-4881-be25-8fe80ed43d0d'; //new Buffer('...') || 'string';
				const SSECustomerKeyMD5 = 'string';
				const SSEKMSKeyId = 'string';


				Storage.put('test.txt', 'File content', {
					serverSideEncryption,
					// SSECustomerAlgorithm,
					// SSECustomerKey,
					// SSECustomerKeyMD5,
					// SSEKMSKeyId
				})
					.then (result => console.log(result))
					.catch (err => console.log(err)); */
			}, 1000);

			console.log('cognitoUser.signInUserSession', cognitoUser.signInUserSession);

			yield put(actions.signIn.success(cognitoUser.signInUserSession));

			const state = yield select();
			/*const { pathname } = getLocation(state);

			if (pathname === 'auth/sign-in') {
				yield put(push('/'));
			}*/
			// return redirect('/');
		}
	} catch (err) {
		console.error('Auth.signIn Error:', err);
		if (err.code === 'UserNotConfirmedException') {
			// The error happens if the user didn't finish the confirmation step when signing up
			// In this case you need to resend the code and confirm the user
			// About how to resend the code and confirm the user, please check the signUp part
		} else if (err.code === 'PasswordResetRequiredException') {
			// The error happens when the password is reset in the Cognito console
			// In this case you need to call forgotPassword to reset the password
			// Please check the Forgot Password part.
		} else if (err.code === 'NotAuthorizedException') {
			// The error happens when the incorrect password is provided
		} else if (err.code === 'UserNotFoundException') {
			// The error happens when the supplied username/email does not exist in the Cognito user pool
		} else {
			//
		}

		yield delay(1000);
		yield put(actions.signIn.failure(err));
	}
}

function* signOut() {
	try {
		yield call([Auth, 'signOut']);
		yield delay(1000);
		yield put(actions.signOut.success())
		return redirect(`/`); // not working: relying on <Navigate> in SignOut.jsx
	} catch(err) {
		console.error(err);
		yield put(actions.signOut.failure(err));
	}
}

function* getAWSCredentials(action) {
	console.info('[saga] getAWSCredentials()');

	try {
		const credentials = yield call([Auth, 'currentCredentials']);
		yield put(actions.getAWSCredentials.success(credentials));
	} catch (err) {
		console.error(err);
		yield put(actions.getAWSCredentials.failure(err));
	}
}

function* postSchwabAccessToken(action) {
	console.info('[saga] postSchwabAccessToken()');

	const params = action.payload;
	const { auth } = yield select();
	const { session } = auth;

	// https://ietn8j98ib.execute-api.us-east-2.amazonaws.com/token/post/schwab
		// https://ox2wcm6bzqnnwiqstwwym6424u0nehgp.lambda-url.us-east-2.on.aws/
	try {
		// const { data } = yield api.post(`/auth/token/post/schwab`, params);
		const token = session.idToken.jwtToken;
		const { data } = yield axios.post('https://ietn8j98ib.execute-api.us-east-2.amazonaws.com/auth/token/post/schwab', params, {
			headers: {
				'Authorization': `Bearer ${token}`,
				'Content-Type': 'application/json',
			}
		});
		yield put(actions.postSchwabAccessToken.success(data, params));
	} catch (err) {
		yield put(actions.postSchwabAccessToken.failure(err));
	}
}

function* postOandaAccessToken(action) {

	console.info('[saga] postOandaAccessToken()');

	const params = action.payload;

	try {
		const { data } = yield api.post(`/auth/token/post/oanda`, params);
		yield put(actions.postOandaAccessToken.success(data, params));
	} catch (err) {
		yield put(actions.postOandaAccessToken.failure(err));
	}
}

function* refreshAmeritradeAccessToken(action) {
	console.info('[saga] refreshAmeritradeAccessToken()');

	const params = action.payload;

	try {
		const { data } = yield api.post(`/auth/token/refresh`, params);
		yield put(actions.refreshAmeritradeAccessToken.success(data, params));
	} catch (err) {
		yield put(actions.refreshAmeritradeAccessToken.failure(err));
	}
}

/**
 * Watchers
 */
function *watchSession() {
	try {
		yield takeLatest(types.SESSION.REQUEST, getSession);
	} catch(err) {
		yield put(actions.getSession.failure(err));
	}
}

function *watchSignIn() {
	try {
		yield takeLatest(types.SIGN_IN.REQUEST, signIn);
	} catch(err) {
		yield put(actions.signIn.failure(err));
	}
}

function *watchSignOut() {
	try {
		yield takeLatest(types.SIGN_OUT.REQUEST, signOut);
	} catch(err) {
		yield put(actions.signOut.failure(err));
	}
}

function *watchAWSCredentials() {
	try {
		yield takeLatest(types.AWS_CREDENTIALS.REQUEST, getAWSCredentials);
	} catch(err) {
		yield put(actions.getAWSCredentialsFailure(err));
	}
}

function *watchPostSchwabAccessToken() {
	try {
		yield takeLatest(types.TOKEN_POST.REQUEST, postSchwabAccessToken);
	} catch(err) {
		yield put(actions.postSchwabAccessToken.failure(err));
	}
}

function *watchRefreshAmeritradeToken() {
	try {
		yield takeLatest(types.TOKEN_REFRESH.REQUEST, refreshAmeritradeAccessToken);
	} catch(err) {
		yield put(actions.refreshAmeritradeAccessToken.failure(err));
	}
}

function *watchPostOandaAccessToken() {
	try {
		yield takeLatest(types.OANDA_TOKEN_POST.REQUEST, postOandaAccessToken);
	} catch(err) {
		yield put(actions.postOandaAccessToken.failure(err));
	}
}

/**
 * Sagas
 */
function* sagas() {
	yield all([
		fork(watchSession),
		fork(watchSignIn),
		fork(watchSignOut),
		fork(watchAWSCredentials),
		fork(watchPostSchwabAccessToken),
		fork(watchRefreshAmeritradeToken),
		fork(watchPostOandaAccessToken),
	]);
}

export default sagas;
