import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withTheme } from '@mui/styles';
import { Button, Card, CardContent } from '@mui/material';

import DebugOutput from '@@Components/DebugOutput';
import { operations as accountOperations, selectors as accountSelectors } from '@@Redux/account';
import { currency } from '@@Utils';


const AccountSummary = ({
	account,
	getAccount,
	name,
}) => {

	useEffect(() => {
		async function fetchData() {
			await getAccount();
			// await props.getOpenPositions();
		}
		fetchData();
	}, []);

	const refresh = () => {
		getAccount({ name });
	}

	const { currentBalances } = account;

	if (account) {
		return (
			<Card>
				<CardContent>
					<DebugOutput data={account} label="account" />
					{name === 'schwab' && (
						<StyledDescriptionList>
							<dt>Account:</dt>



							<dd>xxxxx{account.accountId ? account.accountId.slice(-4) : ''} <small>({account.type})</small></dd>
							{currentBalances && (
								<Fragment>
									<dt>Cash Balance:</dt>
									<dd>{currency(currentBalances.cashBalance)}</dd>
									<dt>Liquidation Value:</dt>
									<dd>{currency(currentBalances.liquidationValue)}</dd>
									<dt>Pending Deposits:</dt>
									<dd>{currency(currentBalances.pendingDeposits)}</dd>
									<dt>Available Funds:</dt>
									<dd>{currency(currentBalances.availableFunds)}</dd>
									<dt>Available Funds (Non-Marginable Trade):</dt>
									<dd>{currency(currentBalances.availableFundsNonMarginableTrade)}</dd>
									<dt>Buying Power:</dt>
									<dd>{currency(currentBalances.buyingPower)}</dd>
									<dt>Buying Power (Non-Marginable Trade):</dt>
									<dd>{currency(currentBalances.buyingPowerNonMarginableTrade)}</dd>
									<dt>Day Trading Buying Power:</dt>
									<dd>{currency(currentBalances.dayTradingBuyingPower)}</dd>
									<dt>Equity:</dt>
									<dd>{currency(currentBalances.equity)}</dd>
									<dt>Equity Percentage:</dt>
									<dd>{currency(currentBalances.equityPercentage)}</dd>
									<dt>Maintenance Requirement:</dt>
									<dd>{currency(currentBalances.maintenanceRequirement)}</dd>
									<dt>Maintenance Call:</dt>
									<dd>{currency(currentBalances.maintenanceCall)}</dd>
									<dt>Margin Balance:</dt>
									<dd>{currency(currentBalances.marginBalance)}</dd>
									<dt>SMA:</dt>
									<dd>{currency(currentBalances.sma)}</dd>
									<dt>Reg T-Call:</dt>
									<dd>{currency(currentBalances.regTCall)}</dd>
									<dt>Long Option Market Value:</dt>
									<dd>{currency(currentBalances.longOptionMarketValue)}</dd>
									<dt>Short Option Market Value:</dt>
									<dd>{currency(currentBalances.shortOptionMarketValue)}</dd>

									<dt>Long Market Value:</dt>
									<dd>{currency(currentBalances.longMarketValue)}</dd>
									<dt>Short Market Value:</dt>
									<dd>{currency(currentBalances.shortMarketValue)}</dd>

									<dt>Long Margin Value:</dt>
									<dd>{currency(currentBalances.longMarginValue)}</dd>
									<dt>Short Margin Value:</dt>
									<dd>{currency(currentBalances.shortMarginValue)}</dd>

									<dt>Short Balance:</dt>
									<dd>{currency(currentBalances.shortBalance)}</dd>

								</Fragment>
							)}
						</StyledDescriptionList>
					)}

					<Button
						variant="outlined"
						color="primary"
						onClick={refresh}>
						Refresh
					</Button>

					{/*<DebugOutput
						data={account}
						label="account"
					/>*/}

				</CardContent>
			</Card>
		)
	}
	return null;
};

const StyledDescriptionList = styled.dl`
	display: flex;
	flex-wrap: wrap;
	dd, dt {
		font-size: 0.9rem;
		display: inline-block;
	}
	dt {
		font-weight: bold;
		width: 50%;
	}
	dd {
		text-align: right;
		margin-left: auto;
	}

`;

const mapStateToProps = (state, ownProps) => {
	const name = ownProps.name || 'schwab'
	return {
		account: accountSelectors.getAccount(state, { name }) || {},
		openPositions: accountSelectors.getOpenPositions(state),
	};
};

export default connect(
	mapStateToProps,
	{
		getAccount: accountOperations.getAccount,
		getOpenPositions: accountOperations.getOpenPositions,
	},
)(withTheme(AccountSummary));
