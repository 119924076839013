import { Amplify } from 'aws-amplify';


export const configureAmplify = () => {
	Amplify.configure({
		Auth: {
			identityPoolId: 'us-east-2:bc21e3fd-4a3b-4fdf-9fa6-516925d25d5a',
			region: 'us-east-2',
			userPoolId: 'us-east-2_7zSkXIr1m',
			userPoolWebClientId: '6sk0fphssk6p8juhto2m6gauip',
		},
		Storage: {
			bucket: 'hacktrading20200221222342-dev',
			region: 'us-east-2',
			identityPoolId: 'us-east-2:bc21e3fd-4a3b-4fdf-9fa6-516925d25d5a',
		},
	});
};
