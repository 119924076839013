import actions from './actions';


const getChain = (symbol, expirationFrom, expirationTo, interval) => (dispatch, getState) => {
	console.info('Operation: getChain()', symbol, expirationFrom, expirationTo, interval);
	return dispatch(actions.getChain.request({ symbol, expirationFrom, expirationTo, interval }));
};


export {
	getChain,
};
