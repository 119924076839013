import { produce } from 'immer';

import socketTypes from '@@Redux/socket/types';
import types from './types';


const positions = (state = {}, action) => produce(state, draft => {
	const { meta, payload, type } = action;
	switch (type) {
		case types.POSITIONS.SUCCESS: {
			return payload;
		}
		case socketTypes.SOCKET.MESSAGE.RECEIVE: {
			if (meta.type === 'position') {
				const id = payload.data.order.id;
				draft[id] = payload.data.order;
				return;
			}
			return state;
		}
		default:
			return state;
	}
});

const reducer = positions;

export default reducer;
