import { all, takeLatest, put, fork } from 'redux-saga/effects';

import { api } from '@@Utils';

import actions from './actions';
import types from './types';

/**
 Workers
 */
function* fetchPositions() {
	try {
		const { data } = yield api.get(`/account/positions`);
		yield put(actions.getPositions.success(data));
	}
	catch (err) {
		yield put(actions.getPositions.failure(err));
	}
}

/**
 * Watchers
 */
function *watchPositions() {
	try {
		yield takeLatest(types.POSITIONS.REQUEST, fetchPositions);
	} catch(err) {
		yield put(actions.getPositions.failure(err));
	}
}

/**
 * Sagas
 */
function* sagas() {
	yield all([
		fork(watchPositions),
	]);
}

export default sagas;
