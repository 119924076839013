import React, { Fragment } from 'react';
import prettyMs from 'pretty-ms';
import Moment from 'react-moment';
import { withTheme } from '@mui/styles';
import {
	Chip,
	Grid2 as Grid,
	Divider,
	ButtonGroup,
	Button,
	Box,
	CardContent,
	CardActions,
	Typography,
	TableFooter,
	TableContainer,
	Table,
	TableHead,
	TableBody,
	TableRow,
	TableCell,
	Paper
} from '@mui/material';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import PlayArrowIcon from '@mui/icons-material/PlayArrow';
import StopIcon from '@mui/icons-material/Stop';

import DebugOutput from '@@Components/DebugOutput';
import QuoteCard from '@@Components/QuoteCard';
import { agentStatuses, dateFormats } from '@@Constants';
import { USD, currency } from '@@Utils';

import * as Styles from './AgentCard.styles';
import { operations as agentOperations, selectors as agentSelectors} from '@@Redux/agents';
import { selectors as quoteSelectors} from '@@Redux/quotes';
import { connect } from 'react-redux';
import { symbol } from 'prop-types';


const AgentCard = ({
	agent,
	onDelete,
	onStart,
	onStop,
}) => {

	const { config, error, agentStatus } = agent;
	const { duration, activity = [] } = agent.agentData;
	// const underlying = config ? config.underlyingSymbol : '';

	const agentBalance = activity.reduce((a, c) => a + c.netAmount, 0);

		return (
			<Box m={2}>
				<Styles.Card>
					<Styles.CardHeader title={
						<Box>
							<Grid container justifyContent="space-between" alignItems="flex-start">
								<Grid xs={8}>
									<Typography component="h6" variant="h6">
										{agent.name}
									</Typography>
									<DebugOutput data={config} />
								</Grid>
								<Grid xs={3} style={{ textAlign: 'right' }}>

									<Fragment>
										<Styles.StatusBadge status={agentStatus}>{agentStatus}</Styles.StatusBadge>
										{error && (
											<Typography component="div" variant="caption" color="error" gutterBottom>
												{error}
											</Typography>
										)}
									</Fragment>

									<Typography variant="caption" component="div" style={{ textAlign: 'right' }} gutterBottom>
										{duration ? prettyMs(duration) : null}
									</Typography>
									<Typography variant="caption">
										{/*Iterations: {iterations && Object.keys(iterations).length}*/}
									</Typography>
									<Typography>
										{/*At Risk: {balances && USD(balances.openingTransactions)}*/}
									</Typography>
								</Grid>
							</Grid>
							<Grid container spacing={4} justifyContent="space-between" alignItems="flex-end">
								<Grid xs={8}>
									<Box mt={2}>
										<Typography color="primary" variant="h1" component="h1" style={{ fontSize: 36 }} >
											{config.underlyingSymbol}
										</Typography>

									</Box>
								</Grid>
								<Grid xs={12} md={4} lg={3}>
									<Typography component="div" variant="body1">
										<Styles.DescriptionList>
											<dt>createdAt:</dt>
											<dd>{agent.agentData.createdAt}</dd>
											<dt>lastSyncedAt:</dt>
											<dd>{agent.agentData.lastSyncedAt}</dd>
										</Styles.DescriptionList>
									</Typography>
								</Grid>
							</Grid>
						</Box>
					}>
					</Styles.CardHeader>

					<CardContent>

						<TableContainer component={Paper} >
							<Table size="small">
								<TableHead>
									<TableRow>
										<TableCell>Date</TableCell>
										<TableCell>Activity/Order</TableCell>
										<TableCell>Type</TableCell>
										<TableCell>Action/Effect</TableCell>
										<TableCell>Instrument</TableCell>
										<TableCell align="center">Qty</TableCell>
										<TableCell align="right">Price</TableCell>
										<TableCell align="right">Fees</TableCell>
										<TableCell align="right">Net</TableCell>
									</TableRow>
								</TableHead>
								<TableBody>


						{activity.map((a) => {
							return (
								<TableRow key={a.activityId}>
									<TableCell style={{whiteSpace: 'nowrap'}}>
										{/*<DebugOutput data={a} label={a.activityId} />*/}
										<small>
										<Moment date={a.time} format={dateFormats.DATE} /><br/>
										<Moment date={a.time} format={dateFormats.TIME} />
										</small>
									</TableCell>
									<TableCell>
										{a.activityId && (<span>#{a.activityId}</span>)}<br/>
										{a.orderId && (<span>#{a.orderId}</span>)}
									</TableCell>
									<TableCell>
									{a.assetType}
									</TableCell>
									<TableCell>
										<span>{a.action}</span>
										{a.positionEffect && (<><br /><small>{a.positionEffect}</small></>)}
									</TableCell>
									<TableCell>
										<Box mb={1}>
											{a.assetType === 'OPTION' && (<>
													<Typography variant="h6" gutterBottom={false}>
														{a.strikePrice} {a.contractType}
													</Typography>
													<Moment date={a.expirationDate} format="DD MMM YY" style={{textTransform: 'uppercase'}} />
												</>
											)}
											{a.assetType !== 'OPTION' && (
												<Typography variant="h6" gutterBottom={false}>
													SHARES
												</Typography>
											)}
										</Box>
										{/*<div>{a.symbol}</div>
										<div><small>#{a.instrumentId}</small></div>*/}

									</TableCell>
									<TableCell align="center">
										{a.quantity}
									</TableCell>
									<TableCell align="right">
										{currency(a.tradePrice).format({ symbol: '' })}
									</TableCell>
									<TableCell align="right">
										{currency(a.fees).format({ symbol: '' })}
									</TableCell>
									<TableCell align="right" style={ a.netAmount < 0 ? { color: 'darkred'} : { color: 'green'}}>
										<strong>{currency(a.netAmount).format()}</strong>
									</TableCell>

								</TableRow>
							)
						})}
								</TableBody>
								<TableFooter>
									<TableRow>
										<TableCell colSpan={10} align="right">
											{currency(agentBalance).format()}
										</TableCell>
									</TableRow>
								</TableFooter>
							</Table>

						</TableContainer>


						{/*<Box my={2}>
							<Typography variant="h3" gutterBottom>Position</Typography>
							{iterations && Object.values(iterations).map((iteration) => (
								<Iteration
									key={iteration.id}
									iteration={iteration}
									quotes={quotes}
									config={config}
								/>
							))}
						</Box>*/}

						{/*<Typography variant="h3" gutterBottom>Quotes</Typography>*/}
						{/*<Grid container spacing={2}>
							{agentData.subscriptions && agentData.subscriptions.OPTION && Object.values(agentData.subscriptions.OPTION).map((symbol) => {
								return (
									<Grid key={symbol} xs={3}>
										<QuoteCard service="OPTION" symbol={symbol} />
									</Grid>
								);
							})}
						</Grid>*/}

						{/*<Box my={1}>
							<Typography variant="body2" style={{ fontWeight: 600 }}>Target Contracts</Typography>
						</Box>*/}

						<Grid container spacing={2} justifyContent="flex-end">
							<Grid size={{ xs: 12, sm: 6, md: 4 }}>
								<Typography component="div" variant="body1">
									{/*totalShareQuantity: 100,
									optionsOpenCallsQuantity: -1,
									optionsOpenQuantity: -1,
									averageShareTradePrice: 29.7907,
									totalOptionPremium: 108.33,
									adjustedShareCost: 28.707400000000003,
									unencumberedShares: 0,*/}

									<Styles.DescriptionList>
										<dt>Total Share Quantity</dt>
										<dd>{agent.agentData.totalShareQuantity}</dd>
										<dt>Unencumbered Shares</dt>
										<dd>{agent.agentData.unencumberedShares}</dd>
										<dt>Options Open Calls Quantity</dt>
										<dd>{agent.agentData.optionsOpenCallsQuantity}</dd>
										<dt>Average Share Trade Price</dt>
										<dd>{currency(agent.agentData.averageShareTradePrice).format()}</dd>
										<dt>Total Option Premium</dt>
										<dd>{currency(agent.agentData.totalOptionPremium).format()}</dd>
										<dt>Adjusted Share Cost (BEP)</dt>
										<dd>{currency(agent.agentData.adjustedShareCost).format()}</dd>

									</Styles.DescriptionList>
								</Typography>
							</Grid>
						</Grid>


						{/*{balances && (
							<Box my={2}>
								<DebugOutput data={balances} />
								<dl>
								</dl>
							</Box>
						)}*/}

					</CardContent>

					<CardActions>
						<Box p={1}>
							<ButtonGroup variant="contained">

								<Button
									disabled={(
										agentStatus !== agentStatuses.IDLE
									)}
									onClick={() => onStart(agent)}>
									<PlayArrowIcon /> Start
								</Button>

								<Button
									disabled={[agentStatuses.ERROR, agentStatuses.IDLE].includes(agentStatus)}
									onClick={() => onStop(agent)}>
									<StopIcon /> Stop
								</Button>

								<Button
									onClick={() => onDelete(agent)}>
									<DeleteForeverIcon /> Delete
								</Button>

							</ButtonGroup>
						</Box>
					</CardActions>

				</Styles.Card>
			</Box>
		);

};

const mapStateToProps = (state, ownProps) => {

	return {

	};
};

const mapDispatchToProps = {
	getAgents: agentOperations.getAgents,
	deleteAgent: agentOperations.deleteAgent,
	startAgent: agentOperations.startAgent,
	stopAgent: agentOperations.stopAgent,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTheme(AgentCard));
