import React, { useState } from 'react';
import { NavLink, Link as RouterLink } from 'react-router';
import { connect } from 'react-redux';
import { withTheme } from '@mui/styles';
import {
	AppBar,
	Divider,
	Grid2 as Grid,
	Box,
	Menu,
	MenuItem,
	Toolbar,
	IconButton,
	Typography, Tooltip,
} from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import LocalAtmIcon from '@mui/icons-material/LocalAtm';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import ShuffleIcon from '@mui/icons-material/Shuffle';
import Zoom from '@mui/material/Zoom';


import {
	useLocation,
	useNavigate,
	useParams,
} from 'react-router';

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return (
			<Component
				{...props}
				router={{ location, navigate, params }}
			/>
		);
	}

	return ComponentWithRouterProp;
}

const Header = () => {

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);

	const handleMenu = event => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<AppBar
			elevation={0}
			variant="outlined"
		>
			<Toolbar>
				<Grid container alignItems="center" justifyContent="flex-start" spacing={4}>
					<Grid size={2}>
						<IconButton component={RouterLink} to="/">
							<Typography variant="h2" color="primary">🤑</Typography>
						</IconButton>
					</Grid>
					<Grid size={8} container spacing={3} justifyContent="center" alignContent="center" alignItems="center">
						{/*<Grid item xs align="center">
							<Tooltip
								arrow
								title="Forex"
								placement="bottom"
								TransitionComponent={Zoom}
							>
								<IconButton component={NavLink} to="/forex">
									<LocalAtmIcon color="inherit" />
								</IconButton>
							</Tooltip>
						</Grid>
						<Grid item xs align="center">
							<Tooltip
								arrow
								title="Crypto"
								placement="bottom"
								TransitionComponent={Zoom}
							>
								<IconButton component={NavLink} to="/crypto">
									<MonetizationOnIcon color="inherit" />
								</IconButton>
							</Tooltip>
						</Grid>*/}
						<Grid size={12} align="center">
							<Tooltip
								arrow
								title="Options"
								placement="bottom"
								TransitionComponent={Zoom}
							>
								<IconButton component={NavLink} to="/options">
									<ShuffleIcon color="inherit" />
								</IconButton>
							</Tooltip>
						</Grid>
					</Grid>
					<Grid size={2}>
						<div className="text-right">
							<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end', whiteSpace: 'nowrap' }}>

								<IconButton
									aria-label="account of current user"
									aria-controls="menu-appbar"
									aria-haspopup="true"
									onClick={handleMenu}
									color="inherit"
								>
									<AccountCircleIcon />
								</IconButton>

								<Menu
									id="menu-appbar"
									anchorEl={anchorEl}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'left',
									}}
									open={open}
									onClose={handleClose}
								>

									<MenuItem onClick={handleClose} component={NavLink} to="/settings">
										<SettingsIcon color="primary" style={{ marginRight: 10 }} />
										Settings
									</MenuItem>

									<Box my={1}>
										<Divider />
									</Box>

									<MenuItem onClick={handleClose} component={NavLink} to="/auth/sign-out">
										<ExitToAppIcon color="primary" style={{ marginRight: 10 }} />
										Sign out
									</MenuItem>
								</Menu>

							</div>
						</div>
					</Grid>
				</Grid>
			</Toolbar>
		</AppBar>
	);
};


const mapStateToProps = (/* state */) => {
	return {};
};

export default connect(
	mapStateToProps,
	{},
)(withRouter(withTheme(Header)));
