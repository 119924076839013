import styled, { css } from 'styled-components';

import { Backdrop, LinearProgress } from '@mui/material';
import { withTheme } from '@mui/styles';

import { transparentize } from 'polished';

/**
 * Styled Material-UI <Backdrop> for full screen background/overlay.
 */
const StyledBackdrop = withTheme(styled(Backdrop)`
	flex-direction: column;
	justify-content: center;
	align-items: center;

	${({ theme }) => {
		return css`
			padding: ${theme.spacing(10)};
			background-color: ${transparentize(0.08, theme.palette.primary.dark)};
			color: ${theme.palette.primary.contrastText};
			z-index: ${theme.zIndex.modal + 1};
		`;
	}}
`);

/**
 * Styled Material-UI <LinearProgress> overriding layout and size
 */
const StyledLinearProgress = withTheme(styled(LinearProgress)`
	display: block;
	width: 50%;
	height: 2px;

	${({ theme }) => {
		return css`
			margin: ${theme.spacing(1)};
		`;
	}}
`);

/**
 * Styled <p> used as label for Linear Progress
 */
const ProgressLabel = withTheme(styled.p`
	${({ theme }) => theme.typography.h5}
	${({ theme }) => {
		return css`
			text-align: center;
			color: ${theme.palette.primary.contrastText};
		`;
	}}
`);

export {
	StyledBackdrop as Backdrop,
	StyledLinearProgress as LinearProgress,
	ProgressLabel,
};

// Display names
StyledBackdrop.displayName = 'StyledBackdrop';
StyledLinearProgress.displayName = 'StyledLinearProgress';
ProgressLabel.displayName = 'ProgressLabel';
