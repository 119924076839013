import { produce } from 'immer';

import { messageTypes } from '@@Constants';;
import socketTypes from '@@Redux/socket/types';
import types from './types';


const agents = (state = {}, action) => produce(state, draft => {
	const { meta, payload, type } = action;
	switch (type) {
		case types.AGENT_CREATE.SUCCESS: {
			const item = payload;
			const { id: agentId } = meta;
			draft[agentId] = item;
			return;
		}
		case types.AGENT_DELETE.SUCCESS: {
			const { id: agentId } = meta;
			delete draft[agentId];
			return;
		}
		case types.AGENTS.SUCCESS: {
			const items = payload;
			items.forEach((item) => {
				draft[item.id] = item;
			});
			return;
		}
		case socketTypes.SOCKET.MESSAGE.RECEIVE: {
			if (payload.type === messageTypes.AGENT.STATUS) {
				const { agentId } = payload;
				if (draft[agentId]) {
					draft[agentId].agentData = payload.data;

					// output dataset to window for console debugging
					// window.dataset = draft[agentId].status.dataset;
					// = new Map(JSON.parse(action.payload.data.dataset)); // only used if dataset is a Map:
				}
				return;
			}
			return;
		}
		default: return state;
	}
});

const reducer = agents;

export default reducer;

