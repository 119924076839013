import types from './types';


const getTransactions = {
	request: () => ({
		type: types.TRANSACTIONS.REQUEST,
	}),
	success: (data) => ({
		type: types.TRANSACTIONS.SUCCESS,
		payload: data,
	}),
	failure: (err) => ({
		type: types.TRANSACTIONS.FAILURE,
		error: true,
		payload: err,
	}),
};


export default {
	getTransactions,
};
