import React from 'react';
import { connect } from 'react-redux';
import { withTheme } from '@mui/styles';
import { Button, Box, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import Moment from 'react-moment';

import { dateFormats } from '@@Constants';
import { selectors as orderSelectors } from '@@Redux/orders';
import { currency } from '@@Utils';
import DebugOutput from "@@Components/DebugOutput";


const OrdersList = (props) => {
	return (
		<Box my={2}>
			<TableContainer component={Paper}>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>Date</TableCell>
							<TableCell>Order</TableCell>
							<TableCell>Status</TableCell>
							<TableCell>Type</TableCell>
							<TableCell>Legs</TableCell>
							<TableCell align="right">Price</TableCell>
							<TableCell />
						</TableRow>
					</TableHead>

					{/*
					{
						"session": "NORMAL",
						"duration": "DAY",
						"orderType": "MARKET",
						"cancelTime": "2024-11-23T02:08:24.233Z",
						"complexOrderStrategyType": "NONE",
						"quantity": 0,
						"filledQuantity": 0,
						"remainingQuantity": 0,
						"requestedDestination": "INET",
						"destinationLinkName": "string",
						"releaseTime": "2024-11-23T02:08:24.233Z",
						"stopPrice": 0,
						"stopPriceLinkBasis": "MANUAL",
						"stopPriceLinkType": "VALUE",
						"stopPriceOffset": 0,
						"stopType": "STANDARD",
						"priceLinkBasis": "MANUAL",
						"priceLinkType": "VALUE",
						"price": 0,
						"taxLotMethod": "FIFO",
						"orderLegCollection": [
					{
						"orderLegType": "EQUITY",
						"legId": 0,
						"instrument": {
						"cusip": "string",
						"symbol": "string",
						"description": "string",
						"instrumentId": 0,
						"netChange": 0,
						"type": "SWEEP_VEHICLE"
					},
						"instruction": "BUY",
						"positionEffect": "OPENING",
						"quantity": 0,
						"quantityType": "ALL_SHARES",
						"divCapGains": "REINVEST",
						"toSymbol": "string"
					}
						],
						"activationPrice": 0,
						"specialInstruction": "ALL_OR_NONE",
						"orderStrategyType": "SINGLE",
						"orderId": 0,
						"cancelable": false,
						"editable": false,
						"status": "AWAITING_PARENT_ORDER",
						"enteredTime": "2024-11-23T02:08:24.233Z",
						"closeTime": "2024-11-23T02:08:24.233Z",
						"tag": "string",
						"accountNumber": 0,
						"orderActivityCollection": [
					{
						"activityType": "EXECUTION",
						"executionType": "FILL",
						"quantity": 0,
						"orderRemainingQuantity": 0,
						"executionLegs": [
					{
						"legId": 0,
						"price": 0,
						"quantity": 0,
						"mismarkedQuantity": 0,
						"instrumentId": 0,
						"time": "2024-11-23T02:08:24.233Z"
					}
						]
					}
						],
						"replacingOrderCollection": ["string"],
						"childOrderStrategies": ["string"],
						"statusDescription": "string"
					}
					*/}


					<TableBody>
						{props.orders.map((order, i) => {

							const {
								orderId,
								status,
								statusDescription,
								enteredTime,
								closeTime,
								tag,
								duration,
								orderType,
								quantity,
								filledQuantity,
								remainingQuantity,
								price,
								orderLegCollection,
								orderActivityCollection,
								cancelable,
							} = order;

							return (
								<TableRow key={orderId} style={ i % 2 === 0 ? { backgroundColor: '#fafafa'} : {}}>
									<TableCell style={{ whiteSpace: 'nowrap' }}>
										<small>
											<Moment date={enteredTime} format={dateFormats.DATE} /><br />
											<Moment date={enteredTime} format={dateFormats.TIME} />
										</small>
									</TableCell>
									<TableCell>
										<span>#{orderId}</span>
									</TableCell>
									<TableCell >
										<span title={statusDescription}>{status}</span>
									</TableCell>
									<TableCell>
										<div>{orderType}</div>
										<small>{duration}</small>
									</TableCell>
									<TableCell>
										{orderLegCollection.map((leg) => {
											const { legId, orderLegType, instrument, instruction, positionEffect, quantity } = leg;
											const { assetType, symbol, description } = instrument;
											return (
												<div key={legId}>
													{instrument.symbol}
													{instrument.description}
												</div>
											);
										})}
										<DebugOutput data={orderActivityCollection} />
									</TableCell>
									<TableCell align="right">{currency(price).format()}</TableCell>
									<TableCell>
										{cancelable && (
											<Button variant="text" color="secondary" onClick={() => { props.onCancel(orderId); }}>
												Cancel
											</Button>
										)}
									</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	)
};

const mapStateToProps = (state, ownProps) => {
	let { orders } = ownProps;

	if (!orders) {
		orders = orderSelectors.getOrders(state, {});
	}

	return {
		orders,
	};
};

export default connect(
	mapStateToProps,
)(withTheme(OrdersList));
