import React, { Fragment, useEffect, useState } from 'react';
import { withTheme } from '@mui/styles';
import { Box, Grid2 as Grid, Button } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { connect } from 'react-redux';
import AddIcon from '@mui/icons-material/Add';

import AgentCard from './AgentCard/AgentCard';
import AgentDialog from './AgentDialog/Wheel';

import { selectors as agentSelectors, operations as agentOperations } from '@@Redux/agents';
//import GammaScalper from './AgentDialog/GammaScalper';
import DoubleCalendar from './AgentDialog/DoubleCalendar';
import currency from 'currency.js';


const AgentDashboard = ({
	getAgents,
	deleteAgent,
	startAgent,
	stopAgent,
	agents,
}) => {

	useEffect(() => {
		async function fetchData() {
			await getAgents();
		}
		void fetchData();
	}, [getAgents]);


	const [dialogIsOpen, setDialogIsOpen] = useState(false);
	const [selectedAgent, setSelectedAgent] = useState({});

	const openDialog = () => {
		setSelectedAgent(null);
		setDialogIsOpen(true);
	};

	const handleEdit = (agent) => {
		console.log('Edit Agent:', agent);
		setDialogIsOpen(true);
		setSelectedAgent(agent);
	};

	const handleDelete = (agent) => {
		console.log('Delete Agent:', agent);
		deleteAgent(agent);
	};

	const handleStart = (agent) => {
		console.log('Start Agent:', agent);
		startAgent(agent);
	};

	const handleStop = (agent) => {
		console.log('Stop Agent:', agent);
		stopAgent(agent);
	};

	const closeDialog = () => {
		setDialogIsOpen(false);
	};

	return (
		<Box my={2}>

			<Button variant="contained" color="primary" onClick={openDialog}>
				Add Options Agent
			</Button>

			<Grid container>
				{agents.map((agent) => (
					<Grid size={12} key={agent.id}>
						{agent && (
							<Fragment>
								<AgentCard
									agent={agent}
									onDelete={handleDelete}
									onStart={handleStart}
									onStop={handleStop}
								/>
							</Fragment>
						)}
					</Grid>
				))}
			</Grid>


			Total Option Premium: {currency(agents.length > 0 && agents.reduce((a,c) => {
				return a + c.agentData.totalOptionPremium;
			}, 0)).format()}

			<AgentDialog isOpen={dialogIsOpen} onClose={closeDialog} agent={selectedAgent} />
			{/*<DoubleCalendar isOpen={dialogIsOpen} onClose={closeDialog} agent={selectedAgent} />*/}

		</Box>
	)
};


const mapStateToProps = (state) => {
	return {
		agents: agentSelectors.getAgents(state, { type: 'OPTION' }),
		messages: agentSelectors.getAgentMessages(state),
	};
};

const mapDispatchToProps = {
	getAgents: agentOperations.getAgents,
	deleteAgent: agentOperations.deleteAgent,
	startAgent: agentOperations.startAgent,
	stopAgent: agentOperations.stopAgent,
}

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTheme(AgentDashboard));
