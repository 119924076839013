import { createSelector } from 'reselect';
import moment from 'moment';

import { truncate } from '@@Utils';


const getSession = (state) => {
	if (state.auth.session && state.auth.session.idToken) {
		return state.auth.session.idToken.payload;
	}
};

const getAWSCredentials = (state) => {
	if (state.auth.aws) {
		return state.auth.aws;
	}
	return {};
};

const getSessionToken = (state) => {
	return state.auth.session.idToken.payload || {};
};

const getSessionRoles = createSelector(
	getSession,
	(session) => {
		return session['cognito:groups'] || [];
	},
);

const getSessionEmail = createSelector(
	getSession,
	(session) => {
		return session.email;
	},
);

const getOanda = (state) => {
	const oanda = { ...state.auth.oanda };
	return oanda;
}

const getAmeritrade = (state, truncated = true) => {

	let ameritrade = { ...state.auth.ameritrade };
	if (ameritrade) {
		if (ameritrade.refreshToken) {
			ameritrade.refreshToken = truncated ? truncate(ameritrade.refreshToken, 50) : ameritrade.refreshToken;
			if (moment().isAfter(moment(ameritrade.refreshTokenExpiresAt, 'X'))) {
				ameritrade.refreshTokenIsExpired = true;
			}
		}
		if (ameritrade.accessToken) {
			ameritrade.accessToken = truncated ? truncate(ameritrade.accessToken, 50) : ameritrade.accessToken;
			if (moment().isAfter(moment(ameritrade.accessTokenExpiresAt, 'X'))) {
				ameritrade.accessTokenIsExpired = true;
			}
		}
	}
	return ameritrade;
};

const getSchwab = (state, truncated = true) => {

	let schwab = { ...state.auth.schwab };
	if (schwab) {
		if (schwab.refreshToken) {
			schwab.refreshToken = truncated ? truncate(schwab.refreshToken, 50) : schwab.refreshToken;
			if (moment().isAfter(moment(schwab.refreshTokenExpiresAt, 'X'))) {
				schwab.refreshTokenIsExpired = true;
			}
		}
		if (schwab.accessToken) {
			schwab.accessToken = truncated ? truncate(schwab.accessToken, 50) : schwab.accessToken;
			if (moment().isAfter(moment(schwab.accessTokenExpiresAt, 'X'))) {
				schwab.accessTokenIsExpired = true;
			}
		}
	}
	return schwab;
};

export {
	getAWSCredentials,
	getAmeritrade,
	getSchwab,
	getOanda,
	getSession,
	getSessionEmail,
	getSessionRoles,
	getSessionToken,
};
