import actions from './actions';


const getOrders = () => (dispatch) => {
	console.info('[operation] getOrders()');
	return dispatch(actions.getOrders.request());
};

const getOrder = (orderId) => (dispatch) => {
	console.info('[operation] getOrder()', orderId);
	return dispatch(actions.getOrder(orderId));
};

const createOrder = () => (dispatch) => {
	console.info('[operation] createOrder()');
	return dispatch(actions.createOrder());
};

const cancelOrder = (params) => (dispatch) => {
	console.info('[operation] cancelOrder()', params);
	dispatch(actions.cancelOrder.request(params))
		.then(() => dispatch(actions.getOrders.request()));
};


export {
	getOrders,
	getOrder,
	createOrder,
	cancelOrder,
};
