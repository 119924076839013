import { all, takeLatest, put, fork } from 'redux-saga/effects';

import { api } from '@@Utils';

import actions from './actions';
import types from './types';

/**
 Workers
 */
function* fetchOrders() {
	try {
		const { data } = yield api.get(`/orders`);
		yield put(actions.getOrders.success(data));
	}
	catch (err) {
		yield put(actions.getOrders.failure(err));
	}
}

function* cancelOrder(action) {
	try {
		const { orderId } = action.payload;
		const { data } = yield api.delete(`/orders/${orderId}`);
		yield put(actions.cancelOrder.success(data));
	}
	catch (err) {
		yield put(actions.cancelOrder.failure(err));
	}
}

/**
 * Watchers
 */
function *watchOrders() {
	try {
		yield takeLatest(types.ORDERS.REQUEST, fetchOrders);
	} catch(err) {
		yield put(actions.getOrders.failure(err));
	}
}

function *watchCancelOrder() {
	try {
		yield takeLatest(types.ORDER_CANCEL.REQUEST, cancelOrder);
	} catch(err) {
		yield put(actions.cancelOrder.failure(err));
	}
}

/**
 * Sagas
 */
function* sagas() {
	yield all([
		fork(watchOrders),
		fork(watchCancelOrder),
	]);
}

export default sagas;
