import { RSAA } from 'redux-api-middleware';

import config from '@@Config';
import types from './types';


const getOrders = {
	request: () => ({
		type: types.ORDERS.REQUEST,
	}),
	success: (data) => ({
		type: types.ORDERS.SUCCESS,
		payload: data,
	}),
	failure: (err) => ({
		type: types.ORDERS.FAILURE,
		error: true,
		payload: err,
	}),
};

const getOrder = (orderId) => {
	const url = `${config.api.hostname}/orders/${orderId}`;
	return {
		[RSAA]: {
			endpoint: url,
			method: 'GET',
			headers: { ...RSAA.headers, 'Content-Type': 'application/json' },
			types: [types.ORDER.REQUEST, types.ORDER.SUCCESS, types.ORDER.FAILURE],
		},
	};
};

const createOrder = () => {
	const url = `${config.api.hostname}/orders`;
	return {
		[RSAA]: {
			endpoint: url,
			method: 'POST',
			headers: { ...RSAA.headers, 'Content-Type': 'application/json' },
			types: [types.ORDER_CREATE.REQUEST, types.ORDER_CREATE.SUCCESS, types.ORDER_CREATE.FAILURE],
		},
	};
};

/*const cancelOrder = (orderId) => {
	const url = `${config.api.hostname}/orders/${orderId}`;
	return {
		[RSAA]: {
			endpoint: url,
			method: 'DELETE',
			headers: { ...RSAA.headers, 'Content-Type': 'application/json' },
			types: [types.ORDER_CANCEL.REQUEST, types.ORDER_CANCEL.SUCCESS, types.ORDER_CANCEL.FAILURE],
		},
	};
};*/

const cancelOrder = {
	request: (params) => ({
		type: types.ORDER_CANCEL.REQUEST,
		payload: params,
	}),
	success: (data) => ({
		type: types.ORDER_CANCEL.SUCCESS,
		payload: data,
	}),
	failure: (err) => ({
		type: types.ORDER_CANCEL.FAILURE,
		error: true,
		payload: err,
	}),
};

export default {
	getOrders,
	getOrder,
	createOrder,
	cancelOrder,
}
