import { Auth, Storage } from 'aws-amplify';
import {
	KmsKeyringBrowser,
	KMS,
	getClient,
	buildClient,
	CommitmentPolicy,
} from '@aws-crypto/client-browser';
import { toBase64 } from '@aws-sdk/util-base64-browser';

import config from '@@Config';
import { stringToArray, arrayToString } from '@@Utils';
import { camelizeKeys } from 'humps';

import actions from './actions';
import * as selectors from './selectors';


const { encrypt, decrypt } = buildClient(
	CommitmentPolicy.REQUIRE_ENCRYPT_REQUIRE_DECRYPT
);



const postOandaAccessToken = (token) => (dispatch) => {
	return dispatch(actions.postOandaAccessToken.request({ token }));
}

const postAmeritradeAccessToken = (code) => (dispatch, getState) => {
	console.log('postAmeritradeAccessToken', code);
	const session = selectors.getSession(getState());
	const credentials = selectors.getAWSCredentials(getState());

	return dispatch(actions.postAmeritradeAccessToken.request({ code }));
		/*.then(async (action) => {
			const payload = camelizeKeys(action.payload);

			console.log('posted payload', payload);

			encryptData(payload, session, credentials).then((result) => {

				//const resultBase64 = encrypted;
				//console.log('resultBase64', resultBase64);

				Storage.put('td', result, {
					level: 'private',
					contentType: 'text/plain',
					ContentEncoding: 'base64',
				})
					.then (result => console.log(result))
					.catch (err => console.log(err));

				//const resultBase64 = toBase64(result);
				//console.log('resultBase64', resultBase64);

				/!* console.log('result', result);
				console.info('keyring', keyring);
				/!* Decrypt the result using the same keyring *!/
				const { plaintext, messageHeader } = await decrypt(keyring, result);


				console.log('xxx');
				//const { plaintext, messageHeader } = await decrypt(keyring, result);

				console.log('plaintext', plaintext);
				console.log('messageHeader', messageHeader);

				/!* Get the encryption context *!/
				const { encryptionContext } = messageHeader;

				/!* Verify that all values in the original encryption context are in the
				 * current one. (The Encryption SDK adds extra values for signing.)
				 *!/
				Object
					.entries(context)
					.forEach(([key, value]) => {
						if (encryptionContext[key] !== value) throw new Error('Encryption Context does not match expected values')
					});

				/!* If the encryption context is verified, log the plaintext. *!/
				console.log(plaintext) *!/

			});

		});*/
};

const postSchwabAccessToken = (code) => (dispatch, getState) => {
	console.log('postSchwabAccessToken', code);
	const session = selectors.getSession(getState());
	const credentials = selectors.getAWSCredentials(getState());

	return dispatch(actions.postSchwabAccessToken.request({ code }));
};

const refreshAmeritradeAccessToken = () => (dispatch, getState) => {
	console.info('[operation] refreshAmeritradeAccessToken()');
	const session = selectors.getSession(getState());
	const credentials = selectors.getAWSCredentials(getState());

	console.log('credentials', credentials);

	const userId = session.sub;
	const email = session.email;
	const identityId = credentials.identityId;
	console.info('[operation] refreshToken()', userId, identityId, email);
	return dispatch(actions.refreshAmeritradeAccessToken.request({ userId, identityId, email }));
};

const refreshSchwabAccessToken = () => (dispatch, getState) => {
	console.info('[operation] refreshSchwabAccessToken()');
	const session = selectors.getSession(getState());
	const credentials = selectors.getAWSCredentials(getState());

	console.log('credentials', credentials);

	const userId = session.sub;
	const email = session.email;
	const identityId = credentials.identityId;
	console.info('[operation] refreshToken()', userId, identityId, email);
	return dispatch(actions.refreshSchwabAccessToken.request({ userId, identityId, email }));
};

const signIn = (username, password, newPassword) => async (dispatch) => {
	console.info('[operation] signIn()', username);
	return dispatch(actions.signIn.request({ username, password, newPassword}));
};

/*const signIn = (username, password) => async (dispatch) => {
	console.info('[operation] signIn()');
	dispatch(actions.signIn.request());
	try {

		const cognitoUser = await Auth.signIn(username, password);
		if (cognitoUser.challengeName === 'SMS_MFA' || cognitoUser.challengeName === 'SOFTWARE_TOKEN_MFA') {
			// You need to get the code from the UI inputs and then trigger the following function with a button click
			const code = getCodeFromUserInput();
			// If MFA is enabled, sign-in should be confirmed with the confirmation code
			const loggedUser = await Auth.confirmSignIn(
				cognitoUser,   // Return object from Auth.signIn()
				code,   // Confirmation code
				mfaType // MFA Type e.g. SMS_MFA, SOFTWARE_TOKEN_MFA
			);
		} else if (cognitoUser.challengeName === 'NEW_PASSWORD_REQUIRED') {
			const { requiredAttributes } = cognitoUser.challengeParam; // the array of required attributes, e.g ['email', 'phone_number']
			// You need to get the new password and required attributes from the UI inputs
			// and then trigger the following function with a button click
			// For example, the email and phone_number are required attributes
			// const { username, email, phone_number } = getInfoFromUserInput();
			const loggedUser = await Auth.completeNewPassword(
				cognitoUser,              // the Cognito User Object
				password,       // the new password
				// OPTIONAL, the required attributes
				{
					//email,
					//phone_number,
				}
			);
		} else if (cognitoUser.challengeName === 'MFA_SETUP') {
			// This happens when the MFA method is TOTP
			// The user needs to setup the TOTP before using it
			// More info please check the Enabling MFA part
			await Auth.setupTOTP(cognitoUser);
		} else {


			await dispatch(getAWSCredentials());

			// The user directly signs in
			setTimeout(async () => {

				/!* const serverSideEncryption = 'aws:kms';
				const SSECustomerAlgorithm = 'AES256';
				const SSECustomerKey = 'e2f2c546-64ea-4881-be25-8fe80ed43d0d'; //new Buffer('...') || 'string';
				const SSECustomerKeyMD5 = 'string';
				const SSEKMSKeyId = 'string';


				Storage.put('test.txt', 'File content', {
					serverSideEncryption,
					// SSECustomerAlgorithm,
					// SSECustomerKey,
					// SSECustomerKeyMD5,
					// SSEKMSKeyId
				})
					.then (result => console.log(result))
					.catch (err => console.log(err)); *!/


				await dispatch(actions.signInSuccess(cognitoUser.signInUserSession));
				//dispatch(errorActions.clearErrors());
			}, 1000);
		}
	} catch (err) {
		console.error('Auth.signIn Error:', err);
		if (err.code === 'UserNotConfirmedException') {
			// The error happens if the user didn't finish the confirmation step when signing up
			// In this case you need to resend the code and confirm the user
			// About how to resend the code and confirm the user, please check the signUp part
		} else if (err.code === 'PasswordResetRequiredException') {
			// The error happens when the password is reset in the Cognito console
			// In this case you need to call forgotPassword to reset the password
			// Please check the Forgot Password part.
		} else if (err.code === 'NotAuthorizedException') {
			// The error happens when the incorrect password is provided
		} else if (err.code === 'UserNotFoundException') {
			// The error happens when the supplied username/email does not exist in the Cognito user pool
		} else {
			//
		}
		setTimeout(() => {
			dispatch(actions.signInFailure(err));
		}, 1000);
	}
};*/

const signOut = () => async (dispatch) => {
	console.info('[operation] signOut()');
	dispatch(actions.signOut.request());
};

const getSession = () => async (dispatch) => {
	console.info('[operation] getSession()');
	dispatch(actions.getSession.request());
};

const getAmeritradeFromLocalStorage = () => (dispatch) => {
	let ameritrade = localStorage.getItem('td');
	if (ameritrade) {
		ameritrade = JSON.parse(ameritrade);
		return dispatch(actions.getAmeritradeFromLocalStorage.success(ameritrade));
	} else {
		return dispatch(actions.getAmeritradeFromLocalStorage.failure('Local Storage Credentials Not Found.'));
	}
};

const getSchwabFromLocalStorage = () => (dispatch) => {
	console.log('xxxx')
	let schwab = localStorage.getItem('schwab');
	console.log('yyyy')
	if (schwab) {
		schwab = JSON.parse(schwab);
		console.log(schwab);
		return dispatch(actions.getSchwabFromLocalStorage.success(schwab));
	} else {
		return dispatch(actions.getSchwabFromLocalStorage.failure('Local Storage Credentials Not Found.'));
	}
};

const getAWSCredentials = () => async (dispatch, getState) => {
	console.info('[operation] getAWSCredentials()');
	return dispatch(actions.getAWSCredentials.request());
};

const encryptData = (data, session, credentials) => {
	return new Promise(async (resolve, reject) => {
		console.log('[operation] encryptToken()', data, session, credentials);

		/** Start by constructing a keyring. We'll create a KMS keyring.
		 * Specify an AWS Key Management Service (AWS KMS) customer master key (CMK) to be the
		 * generator key in the keyring. This CMK generates a data key and encrypts it.
		 * To use the keyring to encrypt data, you need kms:GenerateDataKey permission
		 * on this CMK. To decrypt, you need kms:Decrypt permission.
		 */
		const generatorKeyId = config.aws.kms.encryptKey;

		/**
		 * You can specify additional CMKs for the keyring. The data key that the generator key
		 * creates is also encrypted by the additional CMKs you specify. To encrypt data,
		 *  you need kms:Encrypt permission on this CMK. To decrypt, you need kms:Decrypt permission.
		 */
		const keyIds = [config.aws.kms.encryptKey]; // TODO: update to different key / understand the benefit of using additional keys

		/**
		 * Create KMS client provider with AWS credentials
		 */
		const clientProvider = getClient(KMS, {
			credentials: {
				accessKeyId: credentials.accessKeyId,
				secretAccessKey: credentials.secretAccessKey,
				sessionToken: credentials.sessionToken,
			}
		});

		/**
		 *  Create KMS keyring
		 */
		const keyring = new KmsKeyringBrowser({ clientProvider, generatorKeyId, keyIds });

		/**
		 * Set an encryption context For more information:
		 * https://docs.aws.amazon.com/encryption-sdk/latest/developer-guide/concepts.html#encryption-context
		 */
		const context = {
			userId: session.sub,
			userEmail: session.email,
			origin: config.aws.region,
		};

		/* Create a string to encrypt */
		const plainText = new Uint8Array(stringToArray(JSON.stringify(data)));

		/**
		 *  Encrypt the string using the keyring and the encryption context
		 * the Encryption SDK returns an "encrypted message" (`result`) that includes the ciphertext,
		 * the encryption context, and the encrypted data keys.
		 */
		const { result } = await encrypt(keyring, plainText, { encryptionContext: context });

		resolve(result);
	});
};


export {
	getAmeritradeFromLocalStorage,
	getSchwabFromLocalStorage,
	refreshAmeritradeAccessToken,
	refreshSchwabAccessToken,
	postAmeritradeAccessToken,
	postSchwabAccessToken,
	postOandaAccessToken,
	encryptData,
	signIn,
	signOut,
	getAWSCredentials,
	getSession,
};
