import { Auth } from 'aws-amplify';

import { api } from '@@Utils';


export default () => (next) => async (action) => {

	if (
		(action.type && !action.type.includes('_REQUEST'))
		|| (action.meta && action.meta.credentials === 'omit')
	) {
		return next(action);
	}

	try {
		const session = await Auth.currentSession();
		const token = session.idToken.jwtToken;

		// TODO: Investigate use of axios request interceptors outside this file to avoid currentSession() calls
		api.defaults.headers.Authorization = `Bearer ${token}`;
		return next(action);
	} catch (err) {
		if (err !== 'No current user') {
			console.warn(err);
		}
		return next(action)
	}
};
