import React, {Fragment, useEffect, useState} from 'react';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-tomorrow';
import { withTheme } from '@mui/styles';
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	FormHelperText,
	Grid,
	TextField,
	Dialog,
	DialogContent,
	DialogActions,
	Divider,
	InputAdornment,
	InputLabel,
	MenuItem,
	Typography,
} from '@mui/material';

import ExpirationDates from '@@Components/Options/Agents/ExpirationDates';
import DialogTitle from '@@Components/Dialog/DialogTitle/DialogTitle';
import { selectors as accountSelectors } from '@@Redux/account';
import { operations as agentOperations } from '@@Redux/agents';
import { operations as chainOperations, selectors as chainSelectors } from '@@Redux/chains';
import { operations as socketOperations } from '@@Redux/socket';


const AgentDialog = ({
	agent,
	buyingPower,
	createAgent,
	getChain,
	isOpen,
	onClose,
	updateAgent,
	subscribe,
}) => {

	const [isSymbolSet, setIsSymbolSet] = useState(false);

	const getInitialValues = () => {

		let allocatedFunds = Math.floor(Math.min(buyingPower, 100));

		return {
			name: agent ? agent.name : '',
			underlyingSymbol: 'SPY',
			expirationDateLong: '',
			expirationDateShort: '',
			quantity: 1,
			closingPercentageGain: 10,
			closingPercentageLoss: 25,
			shortDeltaCall: 20,
			shortDeltaPut: 25,
			longDelta: 20,
			buyCondition: 'return true;', // 'return (I_M1.RSI.value > 0);',
			sellCondition: '',
			buyUseTrailingStop: true,
			buyTrailingOffsetPercentage: 2.5,
			sellUseTrailingStop: true,
			sellTrailingOffsetPercentage: 2.5,
		};
	};

	const handleGetChain = (symbol) => {
		const expirationTo = moment().add(90, 'days').format('YYYY-MM-DD');
		getChain(symbol, undefined, expirationTo);
		setIsSymbolSet(true)
	};

	const handleSubmit = (values) => {
		const underlying = values.underlyingSymbol.toUpperCase();
		const agentObj = {};
		if (agent && agent.id) {
			agentObj.id = agent.id;
			// updateAgent(agent);
		} else {
			agentObj.id = uuid();
			agentObj.config = {
				type: 'OPTION',
				name: values.name || '',
				underlyingSymbol: underlying,
				expirationDateLong: values.expirationDateLong,
				expirationDateShort: values.expirationDateShort,
				quantity: values.quantity,
				closingPercentageGain: values.closingPercentageGain / 100,
				closingPercentageLoss: values.closingPercentageLoss / 100,
				shortDeltaCall: values.shortDeltaCall,
				shortDeltaPut: values.shortDeltaPut,
				longDelta: values.longDelta,
				buyCondition: values.buyCondition,
				sellCondition: values.sellCondition,
				buyUseTrailingStop: values.buyUseTrailingStop,
				buyTrailingOffsetPercentage: values.buyTrailingOffsetPercentage,
				sellUseTrailingStop: values.sellUseTrailingStop,
				sellTrailingOffsetPercentage: values.sellTrailingOffsetPercentage,
			};

			createAgent(agentObj);
			subscribe({ service: 'QUOTE', symbol: underlying });
		}
		setIsSymbolSet(false);
		onClose();
	};

	const validateExpirationDates = (dateLong, dateShort) => {
		return moment(dateLong).diff(moment(dateShort), 'days') > 0;
	}

	return (
		<div>
			<Dialog
				open={isOpen}
				onClose={() => {
					setIsSymbolSet(false);
					onClose();
				}}
				aria-labelledby="dialog-title"
				fullWidth
				maxWidth="sm"
			>
				<DialogTitle id="dialog-title" onClose={() => { setIsSymbolSet(false); onClose(); }}>
					Add Double Calendar
				</DialogTitle>

				<Formik
					initialValues={getInitialValues()}
					enableReinitialize={true}
					onSubmit={handleSubmit}
				>
					{({
						values,
						errors,
						touched,
						handleChange,
						handleBlur,
						isSubmitting,
						setFieldValue
					}) => (
						<Form>
							<DialogContent>

								{/*Buying Power: ${buyingPower}*/}

								<Grid container spacing={2} alignItems="center">

									<Grid item xs={4}>
										<TextField
											fullWidth
											id="underlyingSymbol"
											name="underlyingSymbol"
											label="Underlying Symbol"
											placeholder="SPY"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.underlyingSymbol.toUpperCase()}
											inputProps={{ maxLength: 4 }}
											InputLabelProps={{
												shrink: true,
											}}
											variant="filled"
										/>
									</Grid>

									<Grid item>
										<Button
											variant="contained"
											color="secondary"
											disabled={!values.underlyingSymbol}
											onClick={() => handleGetChain(values.underlyingSymbol.toUpperCase())}
										>
											Get Option Chain
										</Button>
									</Grid>
								</Grid>

								<Box my={3}>
									<Divider />
								</Box>

								{isSymbolSet && (
									<Fragment>
										<Grid container spacing={2}>

												<Grid item xs={6}>
													<ExpirationDates
														expirationDate={values.expirationDateShort}
														symbol={values.underlyingSymbol.toUpperCase()}
														onChange={(val) => setFieldValue('expirationDateShort', val)}
														label="Short Expiration Date"
													/>
												</Grid>

												<Grid item xs={6}>
													<ExpirationDates
														expirationDate={values.expirationDateLong}
														symbol={values.underlyingSymbol.toUpperCase()}
														onChange={(val) => setFieldValue('expirationDateLong', val)}
														label="Long Expiration Date"
													/>
												</Grid>
											</Grid>
										<Grid container spacing={2}>
												<Grid item xs={4}>
													<TextField
														fullWidth
														id="shortDelta"
														name="shortDeltaCall"
														label="Short Call Delta"
														type="number"
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.shortDeltaCall}
														helperText=""
														variant="filled"
													/>
												</Grid>
												<Grid item xs={4}>
													<TextField
														fullWidth
														id="shortDelta"
														name="shortDeltaPut"
														label="Short Put Delta"
														type="number"
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.shortDeltaPut}
														helperText=""
														variant="filled"
													/>
												</Grid>
											</Grid>
										<Grid container spacing={2}>

											<Grid item xs={3}>
												<TextField
													fullWidth
													id="quantity"
													name="quantity"
													label="Contract Qty"
													type="number"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.quantity}
													variant="filled"
												/>
											</Grid>

											{/*<Grid item xs>
												<TextField
													fullWidth
													id="longDelta"
													name="longDelta"
													label="Long Delta Target"
													type="number"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.shortDelta}
													helperText=""
													variant="filled"
												/>
											</Grid>*/}

										{/*</Grid>
										<Grid container spacing={2}>*/}

											<Grid item xs={3}>
												<TextField
													fullWidth
													id="closingPercentageGain"
													name="closingPercentageGain"
													label="Close Gain at"
													type="number"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.closingPercentageGain}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">%</InputAdornment>
														),
													}}
													variant="filled"
												/>
											</Grid>
											<Grid item xs={3}>
												<TextField
													fullWidth
													id="closingPercentageLoss"
													name="closingPercentageLoss"
													label="Close Loss at"
													type="number"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.closingPercentageLoss}
													InputProps={{
														endAdornment: (
															<InputAdornment position="end">%</InputAdornment>
														),
													}}
													variant="filled"
												/>
											</Grid>

											<Grid item xs={12}>
												<TextField
													fullWidth
													id="name"
													name="name"
													variant="filled"
													label="Agent Name"
													onBlur={handleBlur}
													onChange={handleChange}
													value={values.name}

												/>
											</Grid>

										</Grid>
									</Fragment>
								)}

							</DialogContent>

							<DialogActions>
								<Button
									type="submit"
									variant="contained"
									color="primary"
									disabled={
										!values.underlyingSymbol
										|| !values.expirationDateLong
										|| !validateExpirationDates(values.expirationDateLong, values.expirationDateShort)
									}
								>
									Save Agent
								</Button>
							</DialogActions>

						</Form>
					)}
				</Formik>
			</Dialog>
		</div>
	);
};

const mapStateToProps = (state, ownProps) => {
	return {
		buyingPower: accountSelectors.getBuyingPower(state),
	};
};

const mapDispatchToProps = {
	createAgent: agentOperations.createAgent,
	getChain: chainOperations.getChain,
	subscribe: socketOperations.subscribe,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTheme(AgentDialog));
