import React, { useEffect, Fragment } from 'react';
import { connect } from 'react-redux';
import { withTheme } from '@mui/styles';
import { Typography, Box, Divider, Paper, TableContainer, Table, TableHead, TableBody, TableRow, TableCell } from '@mui/material';
import Moment from 'react-moment';
import styled from 'styled-components';

import { operations as transactionOperations, selectors as transactionSelectors } from '@@Redux/transactions';
import { dateFormats } from '@@Constants';
import DebugOutput from '@@Components/DebugOutput';

import { currency } from '@@Utils';


const TransactionsList = (props) => {
	return (
		<Box my={2}>
			<TableContainer component={Paper}>
				<Table size="small">
					<TableHead>
						<TableRow>
							<TableCell>Date</TableCell>
							<TableCell>Activity/Order</TableCell>
							<TableCell>Type</TableCell>
							<TableCell>Underlying</TableCell>
							<TableCell>Instrument</TableCell>
							<TableCell>Effect</TableCell>
							<TableCell align="center">Qty</TableCell>
							<TableCell align="center">Fees</TableCell>
							<TableCell align="right">Net</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{props.transactions.map((transaction, i) => {

							const { activityId, amount, description, type, orderId, time, positionId, netAmount, transferItems } = transaction;
							const totalFees = Object.values(transferItems).filter(i => i.feeType).reduce((a, c) => a + c.amount, 0);
							const items = Object.values(transferItems).filter(i => !i.feeType);

							/*
								SCHWAB TRANSACTIONS RESPONSE

								[
								  {
									 "activityId": 0,
									 "time": "2024-11-22T16:41:01.445Z",
									 "user": {
										"cdDomainId": "string",
										"login": "string",
										"type": "ADVISOR_USER",
										"userId": 0,
										"systemUserName": "string",
										"firstName": "string",
										"lastName": "string",
										"brokerRepCode": "string"
									 },
									 "description": "string",
									 "accountNumber": "string",
									 "type": "TRADE",
									 "status": "VALID",
									 "subAccount": "CASH",
									 "tradeDate": "2024-11-22T16:41:01.445Z",
									 "settlementDate": "2024-11-22T16:41:01.445Z",
									 "positionId": 0,
									 "orderId": 0,
									 "netAmount": 0,
									 "activityType": "ACTIVITY_CORRECTION",
									 "transferItems": [
										{
										  "instrument": {
											 "cusip": "string",
											 "symbol": "string",
											 "description": "string",
											 "instrumentId": 0,
											 "netChange": 0,
											 "type": "SWEEP_VEHICLE"
										  },
										  "amount": 0,
										  "cost": 0,
										  "price": 0,
										  "feeType": "COMMISSION",
										  "positionEffect": "OPENING"
										}
									 ]
								  }
								]


							 */

							let rowStyle = (i % 2 === 0) ? { backgroundColor: '#fafafa' } : {};
							if (items[0].positionEffect === 'CLOSING') {
								// rowStyle.opacity = 0.25;
							}

							return (
								<TableRow key={activityId} style={rowStyle}>
									<TableCell style={{ whiteSpace: "nowrap"}}>
										<small>
											<Moment date={time} format={dateFormats.DATE} /><br/>
											<Moment date={time} format={dateFormats.TIME} /></small>
									</TableCell>
									<TableCell>
										<div>#{activityId}</div>
										<div>#{orderId}</div>
									</TableCell>
									<TableCell title={description}>
										<div>{type}</div>
										<small>{items[0].instrument.assetType}</small>
									</TableCell>
									<TableCell>
										<Typography variant="h6">
										{items[0].instrument.optionDeliverables
											? items[0].instrument.optionDeliverables[0].rootSymbol
											: items[0].instrument.symbol
										}</Typography>
									</TableCell>
									<TableCell>
										{(items[0] && items[0].instrument && type === 'TRADE') && (
											<Fragment>
												<Box mb={1}>
													{items[0].instrument.optionDeliverables && (<>
													<Typography variant="h6" gutterBottom={false}>
														{items[0].instrument.strikePrice} {items[0].instrument.putCall}
													</Typography>
													<Moment date={items[0].instrument.expirationDate} format="DD MMM YY" style={{textTransform: 'uppercase'}} />
														</>
													)}
													{!items[0].instrument.optionDeliverables && (
														<Typography variant="h6" gutterBottom={false}>
															SHARES
														</Typography>
													)}
												</Box>
												<div>{items[0].instrument.symbol}</div>
												<div><small>#{items[0].instrument.instrumentId}</small></div>
												{/*<Box my={1}>
													<Typography variant={"caption"}>{items[0].instrument.description}</Typography>
												</Box>*/}

												{/*<DebugOutput data={items[0].instrument} />*/}
											</Fragment>
										)}
									</TableCell>
									<TableCell>
										{(items[0] && items[0].instrument && type === 'TRADE') && (
											<Fragment>
												{items[0].positionEffect}
											</Fragment>
										)}
									</TableCell>
									<TableCell align="center">
										{items[0].amount}
									</TableCell>
									<TableCell align="center">
										{(totalFees > 0) && (
											<Fragment>
												{currency(totalFees).format()}
											</Fragment>
										)}
									</TableCell>
									<TableCell align="right" style={ netAmount < 0 ? { color: 'darkred'} : { color: 'green'}}>
										<strong>{currency(netAmount).format()}</strong>
									</TableCell>
								</TableRow>
							)
						})}
					</TableBody>
				</Table>
			</TableContainer>
		</Box>
	);
};


const mapStateToProps = (state, ownProps) => {
	let { symbol, transactions } = ownProps;

	if (!transactions) {
		transactions = transactionSelectors.getTransactions(state, { symbol });
	}

	return {
		transactions,
	};
};

export default connect(
	mapStateToProps,
)(withTheme(TransactionsList));
