const TOKEN_POST = {
	name: 'auth/TOKEN_POST',
	REQUEST: 'auth/TOKEN_POST_REQUEST',
	SUCCESS: 'auth/TOKEN_POST_SUCCESS',
	FAILURE: 'auth/TOKEN_POST_FAILURE',
};

const OANDA_TOKEN_POST = {
	name: 'auth/OANDA_TOKEN_POST',
	REQUEST: 'auth/OANDA_TOKEN_POST_REQUEST',
	SUCCESS: 'auth/OANDA_TOKEN_POST_SUCCESS',
	FAILURE: 'auth/OANDA_TOKEN_POST_FAILURE',
};

const TOKEN_REFRESH = {
	name: 'auth/TOKEN_REFRESH',
	REQUEST: 'auth/TOKEN_REFRESH_REQUEST',
	SUCCESS: 'auth/TOKEN_REFRESH_SUCCESS',
	FAILURE: 'auth/TOKEN_REFRESH_FAILURE',
};

const AMERITRADE_CREDENTIALS = {
	name: 'auth/AMERITRADE_CREDENTIALS',
	REQUEST: 'auth/AMERITRADE_CREDENTIALS_REQUEST',
	SUCCESS: 'auth/AMERITRADE_CREDENTIALS_SUCCESS',
	FAILURE: 'auth/AMERITRADE_CREDENTIALS_FAILURE',
};

const SCHWAB_CREDENTIALS = {
	name: 'auth/SCHWAB_CREDENTIALS',
	REQUEST: 'auth/SCHWAB_CREDENTIALS_REQUEST',
	SUCCESS: 'auth/SCHWAB_CREDENTIALS_SUCCESS',
	FAILURE: 'auth/SCHWAB_CREDENTIALS_FAILURE',
};

const AWS_CREDENTIALS = {
	name: 'auth/AWS_CREDENTIALS',
	REQUEST: 'auth/AWS_CREDENTIALS_REQUEST',
	SUCCESS: 'auth/AWS_CREDENTIALS_SUCCESS',
	FAILURE: 'auth/AWS_CREDENTIALS_FAILURE',
};

const SESSION = {
	name: 'auth/SESSION',
	REQUEST: 'auth/SESSION_REQUEST',
	SUCCESS: 'auth/SESSION_SUCCESS',
	FAILURE: 'auth/SESSION_FAILURE',
};

const SIGN_IN = {
	name: 'auth/SIGN_IN',
	REQUEST: 'auth/SIGN_IN_REQUEST',
	SUCCESS: 'auth/SIGN_IN_SUCCESS',
	FAILURE: 'auth/SIGN_IN_FAILURE',
};

const SIGN_OUT = {
	name: 'auth/SIGN_OUT',
	REQUEST: 'auth/SIGN_OUT_REQUEST',
	SUCCESS: 'auth/SIGN_OUT_SUCCESS',
	FAILURE: 'auth/SIGN_OUT_FAILURE',
};

export default {
	AMERITRADE_CREDENTIALS,
	SCHWAB_CREDENTIALS,
	AWS_CREDENTIALS,
	OANDA_TOKEN_POST,
	SESSION,
	SIGN_IN,
	SIGN_OUT,
	TOKEN_POST,
	TOKEN_REFRESH
};
