const { localStorage } = global;
const name = 'hacktrading';
const shortName = 'trader';
const description = 'hacktrading';
const basePageTitle = 'hacktrading';

const table = {
	onChangeRowsPerPage: (pageSize) => {
		if (localStorage) {
			localStorage.setItem('pageSize', pageSize.toString());
		}
	},
	options: () => ({
		emptyRowsWhenPaging: false,
		exportButton: false,
		pageSize: (() => {
			const savedPageSize = (localStorage) ? localStorage.getItem('pageSize') : null
				return (savedPageSize) ? Number(savedPageSize) : 25
		})(),
		pageSizeOptions: [10, 25, 50, 100],
		paging: true,
		sorting: true,
	}),
	title: null,
};
const webSocketUrl = process.env.WEB_SOCKET_URL;

module.exports = {
	basePageTitle,
	name,
	shortName,
	description,
	env: process.env.NODE_ENV,
	api: {
		hostname: process.env.API_BASE_URL,
		port: process.env.PORT,
		baseUrl: process.env.API_BASE_URL,
	},
	debug: {
		output: true,
	},
	aws: {
		region: 'us-east-2',
		maxRetries: 10,
		timeout: 20000,
		s3: {
			buckets: {}
		},
		cognito: {
			region: 'us-east-2',
			userPoolId: 'us-east-2_7zSkXIr1m',
			clientId: '6sk0fphssk6p8juhto2m6gauip',
			userPoolArn: 'us-east-2:981077377925:userpool/us-east-2_7zSkXIr1m',
			identityPool: 'us-east-2:bc21e3fd-4a3b-4fdf-9fa6-516925d25d5a',
		},
		kms: {
			encryptKey: 'arn:aws:kms:us-east-2:981077377925:alias/htKMSKey',
		},
	},
	ameritrade: {
		apiKey: 'BLACK1902_03@AMER.OAUTHAP',
		webSocketUrl: `wss://streamer-ws.tdameritrade.com/ws`,
		authenticationUrl: `https://auth.tdameritrade.com/auth?response_type=code&redirect_uri=https%3A%2F%2Flocalhost%3A3000%2Fameritrade&client_id=BLACK1902_03%40AMER.OAUTHAP`,
	},
	schwab: {
		authenticationUrl: 'https://api.schwabapi.com/v1/oauth/authorize?response_type=code&client_id=fnB6k1X6JSFlQHravRt6T9m86AZlkD04&scope=readonly&redirect_uri=https://developer.schwab.com/oauth2-redirect.html',
	},
	table,
	webSocketUrl,
};
