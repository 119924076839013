import io from 'socket.io-client';

import config from '@@Config';
import { messageTypes } from '@@Constants';
import actions from '@@Redux/socket/actions';
import types from '@@Redux/socket/types';


const socketMiddleware = () => {
	let socket = null;

	const onConnect = (store) => (event) => {
		console.info(`[socket] connected (${config.webSocketUrl})`);
		store.dispatch(actions.socketConnected(event));
	};

	const onClose = (store) => () => {
		store.dispatch(actions.socketDisconnected());
	};

	const onMessage = (store) => (event) => {
		// console.log('onMessage', event);
		store.dispatch(actions.receiveMessage(event));
	};

	return (store) => (next) => (action) => {
		switch (action.type) {

			case types.SOCKET.CONNECT:
				console.info(`[socket] connecting… (${config.webSocketUrl})`);

				if (socket !== null) {
					socket.close();
				}

				socket = io(
					config.webSocketUrl,
					{
						'force new connection' : true,
						reconnectionAttempts: 'Infinity',
						reconnect: true,
						timeout: 10000,
						transports : ['websocket'],
						rejectUnauthorized: false,
					}
				);

				socket.on('connect', onConnect(store));
				socket.on(messageTypes.AGENT.STATUS, onMessage(store));
				socket.on(messageTypes.MESSAGE.ALL, onMessage(store));
				socket.on(messageTypes.MESSAGE.BROWSER, onMessage(store));
				socket.on(messageTypes.HEARTBEAT, onMessage(store));
				socket.on('AMERITRADE:LEVELONE_FUTURES', onMessage(store));
				socket.on('AMERITRADE:QUOTE', onMessage(store));
				socket.on('AMERITRADE:OPTION', onMessage(store));
				socket.on('OANDA:PRICE', onMessage(store));
				socket.on('COINBASE:TICKER', onMessage(store));
				socket.on('disconnect', onClose(store));

				break;

			case types.SOCKET.DISCONNECT:
				console.info('[socket] disconnecting…');
				if (socket !== null) {
					socket.close();
				}
				socket = null;
				console.info('[socket] closed');
				break;

			case types.SOCKET.SUBSCRIBE:
				const { service, symbol } = action.payload;
				const state = store.getState();
				const { email, sub } = state.auth.session.idToken.payload;

				// let serviceSubscriptions = [];
				// if (state.socket.subscriptions[service]) {
				// 	serviceSubscriptions = state.socket.subscriptions[service].slice();
				// }
				// if (!serviceSubscriptions.includes(symbol)) {
				// 	serviceSubscriptions.push(symbol);
				// }

				if (socket) {
					socket.emit('AGENT:SUBSCRIBE', {
						agentName: email ? email.split('@')[0] : '',
						agentId: sub,
						data: {
							service,
							symbol,
						}
					});
				}

				return next(action);

			default:
				return next(action);
		}
	};
};

export default socketMiddleware();
