import React, { Fragment, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router';
import { connect } from 'react-redux';
import { Helmet } from 'react-helmet';

import { Box, Toolbar, Container, CssBaseline } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import AgentDashboard from '@@Components/Options/Agents/AgentDashboard';
import AuthSignIn from '@@Components/Auth/SignIn';
import AuthSignOut from '@@Components/Auth/SignOut';
// import Crypto from '@@Components/Crypto/Crypto';
// import Forex from '@@Components/Forex/Forex';
// import Futures from '@@Components/Futures/Futures';
import Options from '@@Components/Options/Options';
import ProgressIndicator from '@@Components/ProgressIndicator/ProgressIndicator';
import Settings from '@@Components/Settings/Settings';
import config from '@@Config';
import { operations as authOperations, selectors as authSelectors } from '@@Redux/auth';
import { operations as socketOperations } from '@@Redux/socket';
import theme, { colors } from '@@Theme';
import GlobalStyle from '@@Theme/components/GlobalStyle';

import Header from './Header';

import {
	useLocation,
	useNavigate,
	useParams,
} from 'react-router';

function withRouter(Component) {
	function ComponentWithRouterProp(props) {
		let location = useLocation();
		let navigate = useNavigate();
		let params = useParams();
		return (
			<Component
				{...props}
				router={{ location, navigate, params }}
			/>
		);
	}

	return ComponentWithRouterProp;
}


const App = ({
	connect,
	session,
	getAWSCredentials,
	getSession,
}) => {

	const { sub: userId } = session || {};

	const [selectedTab, setSelectedTab] = useState(1);

	useEffect(() => {
		async function fetchData() {
			await getSession();

			if (userId) {
				connect();
				getAWSCredentials();
			}
		}
		void fetchData();
	}, [userId, getSession,connect, getAWSCredentials]);

	const handleTabChange = (event, newValue) => {
		setSelectedTab(newValue);
	};

	return (
		<StyledEngineProvider injectFirst>
			<ThemeProvider theme={theme}>
				<CssBaseline />
				<GlobalStyle />

				<Helmet
					titleTemplate={`%s- ${config.basePageTitle}`}
					defaultTitle={config.basePageTitle}
				>
					<meta name="theme-color" content={colors.primary['500']} />
				</Helmet>

				<ProgressIndicator />

				{!session && (
					<Routes>
						<Route path="/" element={<AuthSignIn />} />
						<Route path="/auth/sign-out" element={<AuthSignOut />} />
						<Route exact path="auth/sign-in" element={<AuthSignIn />} />
					</Routes>
				)}

				{/*{!session && <Navigate to="/auth/sign-in" replace={true} />}*/}

				{session && (
					<Fragment>
						<Header
							onTabChange={handleTabChange}
							selectedTab={selectedTab}
						/>

						<Container id="main" tabIndex={-1} component="main" maxWidth={false}>

							{/* this exists solely to create fixed <AppBar> offset for content */}
							<Toolbar />

							<Box my={3}>
								<Routes>
									<Route path="/*" element={<Options />} />
									<Route path="/auth/sign-out" element={<AuthSignOut />} />
									<Route path="/agents-dashboard" element={<AgentDashboard />} />
									{/*<Route exact path="crypto" render={(props) => (<Crypto {...props} />)}/>*/}
									{/*<Route exact path="forex" render={(props) => (<Forex {...props} />)}/>*/}
									{/*<Route exact path="/futures" render={(props) => (<Futures {...props} />)}/>*/}
									<Route path="/settings" element={<Settings />} />
									<Route path="/options/*" element={<Options />} />
								</Routes>
							</Box>

						</Container>
					</Fragment>
				)}

			</ThemeProvider>
		</StyledEngineProvider>
	);
};

const mapStateToProps = (state) => {
	return {
		credentials: authSelectors.getAWSCredentials(state),
		session: authSelectors.getSession(state),
	};
};

const mapDispatchToProps = {
	connect: socketOperations.connect,
	getAWSCredentials: authOperations.getAWSCredentials,
	getSession: authOperations.getSession,
	subscribe: socketOperations.subscribe,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withRouter(App));

