import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Typography, Box, Divider } from '@mui/material';

import { operations as transactionOperations, selectors as transactionSelectors } from '@@Redux/transactions';

import DebugOutput from '@@Components/DebugOutput';
import TransactionsList from './TransactionsList';


const propTypes = {
	getTransactions: PropTypes.func,
	transactions: PropTypes.arrayOf(PropTypes.objectOf(PropTypes.any)),
};

const Transactions = ({
	getTransactions,
	transactions,
}) => {

	useEffect(() => {
		async function fetchData() {
			await getTransactions();
		}
		void fetchData();
	}, [getTransactions]);

	return (
		<Box m={2}>
			<Typography gutterBottom variant="h1">
				Transactions
			</Typography>

			<TransactionsList transactions={transactions} />

			<Divider />

			<DebugOutput
				data={transactions}
				label="transactions"
			/>

		</Box>
	)
};

Transactions.propTypes = propTypes;

const mapStateToProps = (state) => {
	return {
		transactions: transactionSelectors.getTransactions(state, {}),
	};
};

const mapDispatchToProps = {
	getTransactions: transactionOperations.getTransactions,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(Transactions);
