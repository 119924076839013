import { produce } from 'immer';
import moment from 'moment';

import types from './types';
import { dateFormats } from "@@Constants/dateFormats";
import expirationDates from "@@Components/Options/Agents/ExpirationDates";


const chains = (state = {}, action) => produce(state, draft => {
	switch (action.type) {
		case types.CHAIN.SUCCESS: {
			const { payload, meta } = action;
			const { symbol, strategy, interval: defaultInterval } = payload;
			let { interval } = meta;

			if (!draft[symbol]) {draft[symbol] = {}; }
			if (!draft[symbol][strategy]) { draft[symbol][strategy] = {}; }

			draft[symbol][strategy].intervals = payload.intervals;
			draft[symbol][strategy].isDelayed = payload.isDelayed;
			draft[symbol][strategy].expirationDates = {};

			if (!interval) {
				draft[symbol][strategy].defaultInterval = defaultInterval;
				interval = defaultInterval;
			}

			if (interval && payload.monthlyStrategyList) {
				// draft[symbol][strategy][interval] = payload.monthlyStrategyList[0].optionStrategyList;
				payload.monthlyStrategyList.forEach((dateList) => {
					const date = moment([dateList.year, moment().month(dateList.month).format('M') - 1, dateList.day]).format(dateFormats.DATE);
					if (!draft[symbol][strategy].expirationDates[date]) { draft[symbol][strategy].expirationDates[date] = {}; }
					draft[symbol][strategy].expirationDates[date][interval] = dateList.optionStrategyList;
				});
			} else if (payload.expirationDates && payload.callExpDateMap) {
				payload.expirationDates.forEach((expirationDate) => {
					draft[symbol][strategy].expirationDates[expirationDate] = {
						call: payload.callExpDateMap,
						put: payload.putExpDateMap,
					}; // payload.callExpDateMap.find(())
				});

			}
			return;
		}
		default: return state;
	}
});

const reducer = chains;

export default reducer;

