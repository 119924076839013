import React, {Fragment, useEffect} from 'react';
import {connect} from 'react-redux';
import Moment from 'react-moment';
import { Formik, Form } from 'formik';
import styled from 'styled-components';

import { withTheme } from '@mui/styles';
import { Box, Button, Grid, TextField, Typography } from '@mui/material';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import CachedIcon from '@mui/icons-material/Cached';

import config from '@@Config';
import {operations as authOperations, selectors as authSelectors} from '@@Redux/auth';


const Schwab = ({
	schwab,
	getSchwabFromLocalStorage,
	refreshSchwabAccessToken,
	postSchwabAccessToken,
}) => {

	const openWindow = () => {
		window.open(config.schwab.authenticationUrl, '_blank', 'toolbar=0,status=0,width=548,height=460');
	};

	useEffect(() => {
		async function fetchData() {
			await getSchwabFromLocalStorage();
		}
		fetchData();
	}, [getSchwabFromLocalStorage]);

	const {
		accessToken,
		refreshToken,
		accessTokenIsExpired,
		refreshTokenIsExpired,
		accessTokenExpiresAt,
		refreshTokenExpiresAt
	} = schwab;

	return (
		<Fragment>
			<Typography component="h2" variant="h3" gutterBottom>
				Schwab Access
			</Typography>

			{/*{(!refreshToken || refreshTokenIsExpired) && (*/}
				<Box>
					<Typography component="h6" variant="subtitle1">
						To generate tokens:
					</Typography>

					<Typography variant="caption">
						https://api.schwabapi.com/v1/oauth/authorize?response_type=code&client_id=fnB6k1X6JSFlQHravRt6T9m86AZlkD04&scope=readonly&redirect_uri=https://developer.schwab.com/oauth2-redirect.html
					</Typography>

					<Grid container>
						<Grid item xs={10}>
							<ol>
								<li>
									<Box mb={1}>
										<Button
											size="small"
											variant="contained"
											color="primary"
											onClick={openWindow}
										>
											Sign in to Schwab
										</Button>
									</Box>
								</li>
								<li>Allow / authorize this application</li>
								<li>After allowing, copy the resulting error page url's 'code' parameter value<br />(all characters between <code>?code=</code> and <code>&session=</code>)
									from the address bar.
									<Typography variant="caption" component="div">Note: This code may only be used once. You will need to re-authenticate each time.</Typography>
								</li>
							</ol>
						</Grid>
					</Grid>

					<Formik
						initialValues={{
							code: '',
						}}
						validate={values => {
							const errors = {};
							if (!values.code) {
								errors.code = 'Code is required.';
							}
							return errors;
						}}
						onSubmit={(values, { setSubmitting }) => {
							setTimeout(() => {
								setSubmitting(false);

								postSchwabAccessToken(values.code);
							}, 400);
						}}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
							/* and other goodies */
						}) => (
							<Form>

								<Grid container>
									<Grid item xs={12} sm={8} md={6}>
										<TextField
											size="small"
											variant="filled"
											fullWidth
											id="code"
											name="code"
											label="Token Code"
											onBlur={handleBlur}
											onChange={handleChange}
											value={values.code}
											error={(errors.code && touched.code)}
											helperText={(errors.code && touched.code) && errors.code}
										/>
									</Grid>
								</Grid>
								<Box my={2}>
									<Button
										variant="contained"
										color="primary"
										type="submit"
										disabled={isSubmitting||!values.code}
									>
										Generate Access Token
									</Button>
								</Box>
							</Form>
						)}
					</Formik>
				</Box>
			{/*)}*/}

			{/*{(refreshToken && !refreshTokenIsExpired) && (*/}
				<Grid container>
					<Grid item xs={12}>

						<Box my={2}>
							<Typography component="h3" variant="subtitle1">
								Access Token
							</Typography>

							<Grid container>
								<Grid item>
									<VpnKeyIcon color={accessTokenIsExpired ? 'primary' : 'secondary'} />
								</Grid>
								<Grid item>
									<StyledCode color={accessTokenIsExpired ? 'primary' : 'secondary'} component="code" variant="body2" style={{ overflowWrap: 'break-word' }}>
										{accessToken}
									</StyledCode>
								</Grid>
							</Grid>

							<Typography component="div" variant="caption" gutterBottom>
								{accessTokenIsExpired ? 'Expired' : 'Expires'}:&nbsp;
								<Moment parse="X" date={accessTokenExpiresAt} format="dddd, MMMM Do YYYY, h:mm:ss a" />
							</Typography>

							<Button size="small" variant="contained" color="primary" onClick={() => { refreshSchwabAccessToken(); }}>
								<CachedIcon /> Refresh Access Token
							</Button>

						</Box>

						<Box my={2}>
							<Typography component="h3" variant="subtitle1">
								Refresh Token
							</Typography>

							<Grid container>
								<Grid item>
									<VpnKeyIcon color={refreshTokenIsExpired ? 'primary' : 'secondary'} />
								</Grid>
								<Grid item>
									<StyledCode color={refreshTokenIsExpired ? 'primary' : 'secondary'} component="code" variant="body2" style={{ overflowWrap: 'break-word' }}>
										{refreshToken}
									</StyledCode>
								</Grid>
							</Grid>

							<Typography component="div" variant="caption">
								{refreshTokenIsExpired ? 'Expired' : 'Expires'}:&nbsp;
								<Moment parse="X" date={refreshTokenExpiresAt} format="dddd, MMMM Do YYYY, h:mm:ss a" />
							</Typography>
						</Box>

					</Grid>
				</Grid>
			{/*)}*/}

		</Fragment>
	);
};

const StyledCode = styled(Typography)`
	font-family: 'Overpass Mono', monospace;
	line-height: 1.25em;
	display: inline-block;
	margin: 0.25em 0.5em;
`;

const mapStateToProps = (state) => {
	return {
		schwab: authSelectors.getSchwab(state),
	};
};

export default connect(
	mapStateToProps,
	{
		getSchwabFromLocalStorage: authOperations.getSchwabFromLocalStorage,
		postSchwabAccessToken: authOperations.postSchwabAccessToken,
		refreshSchwabAccessToken: authOperations.refreshSchwabAccessToken,
	},
)(withTheme(Schwab));

