import React, { useEffect } from 'react';
import { connect } from 'react-redux';

import { operations } from '@@Redux/auth';
import { Navigate } from 'react-router';


const SignOut = ({
	signOut,
}) => {

	useEffect(() => {
		signOut();
	}, [signOut]);

	return <Navigate to='/' replace />
};

const mapStateToProps = (/* state */) => {
	return {};
};

const mapDispatchToProps = {
	signOut: operations.signOut,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(SignOut);
