import actions from './actions';


const getTransactions = () => (dispatch) => {
	console.info('[operation] getTransactions()');
	return dispatch(actions.getTransactions.request());
};

/*const getTransaction = (transactionId) => (dispatch) => {
	console.info('[operation] getTransaction()');
	return dispatch(actions.getTransaction.request(transactionId));
};*/

export {
	getTransactions,
	// getTransaction,
};
