
const getOrders = (state, { status }) => {
	let orders = Object.values(state.orders) || [];
	orders.sort((a, b) => b.orderId - a.orderId);
	if (status) {
		orders = orders.filter(o => o.status === status);
	}
	return orders;
};

const getOrder = (state, orderId) => {
	return state.orders[orderId];
};

export {
	getOrders,
	getOrder,
}
