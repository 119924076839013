import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { withTheme } from '@mui/styles';
import { Box, Card, Divider, Grid, Typography, CardHeader } from '@mui/material';
import Moment from 'react-moment';

import { currency, getPipFactor } from '@@Utils';
import { selectors as quoteSelectors } from '@@Redux/quotes';
import { operations as socketOperations } from '@@Redux/socket';

import Price from '@@Components/Forex/Price';
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

// {"UBER":{"symbol":"UBER","description":"Uber Technologies, Inc. Common Stock","bid":29.64,"ask":29.65,"last":29.6422,"bidSize":14,"askSize":7},
// "UBER_082120C30.5":{"symbol":"UBER_082120C30.5","cusip":"0UBER.HL00030500","underlying":"UBER","description":"UBER Aug 21 2020 30.5 Call","strikePrice":30.5,"contractType":"C","multiplier":100,"bid":0.33,"ask":0.34,"last":0.34,"mark":0.335,"high":0.5,"low":0.2,"close":0.5,"netChange":-0.16,"volume":869,"openInterest":3524,"timeValue":0.34,"intrinsicValue":-0.85,"volatility":48.21,"delta":0.3182,"gamma":0.2133,"theta":-0.0597,"vega":0.0124,"rho":0.0012,"iv":54.11},"UBER_082120P28.5":{"symbol":"UBER_082120P28.5","cusip":"0UBER.TL00028500","underlying":"UBER","description":"UBER Aug 21 2020 28.5 Put","strikePrice":28.5,"contractType":"P","multiplier":100,"bid":0.31,"ask":0.33,"last":0.31,"mark":0.32,"high":0.4,"low":0.24,"close":0.265,"netChange":0.045,"volume":399,"openInterest":1863,"timeValue":0.31,"intrinsicValue":-1.15,"volatility":56.0795,"delta":-0.2624,"gamma":0.1675,"theta":-0.0634,"vega":0.0113,"rho":-0.0011,"iv":63.13},"UBER_082120C31":

const QuoteCard = ({
	service,
	subscribe,
	symbol,
	quote,
	heading,
}) => {

	const pipFactor = getPipFactor(symbol)
	const decimals = pipFactor === 100 ? 3 : 5;

	useEffect(() => {
		if (service && symbol) {
			subscribe({ service, symbol });
		}
	}, [service, symbol]);


		return (
			<Box width="100%" height="100%">
				<Card variant="outlined" style={{ height: "100%" }}>
					{service !== 'FOREX' && (
						<CardHeader title={(
							<Fragment>
								{heading && (
									<Fragment>
										<Typography variant="h6" align="center" style={{ fontWeight: 600 }}>{heading}</Typography>
										<Box p={1}>
											<Divider />
										</Box>
										{!symbol && (
											<Box m={1} alignContent="center">
												<Typography variant="h3" color="error" align="center">
													<ErrorOutlineIcon /><br />Error
												</Typography>
											</Box>
										)}
										<Typography variant="subtitle1" align="center">{symbol}</Typography>
										{quote && (
											<Typography variant="caption" component="div" align="center">{quote.description}</Typography>
										)}
									</Fragment>
								)}
								{!heading && (
									<Fragment>
										<Typography variant="subtitle1">{symbol}</Typography>
										{quote && (
											<Typography variant="caption" component="div">{quote.description}</Typography>
										)}
									</Fragment>
								)}
							</Fragment>
						)} />
					)}
					{(quote && quote.bid && quote.ask && service === 'FOREX') && (
						<Box p={2}>
							<Grid container spacing={5}>
								<Grid item>
									<Typography variant="caption" component="div">BID</Typography>
									<Typography variant="h5">
										<Price price={quote.bid} decimals={decimals} />
									</Typography>
								</Grid>
								<Divider orientation="vertical" flexItem />
								<Grid item>
									<Typography variant="caption" component="div">SPREAD</Typography>
									<strong>{((quote.ask - quote.bid) * pipFactor).toFixed(1)}</strong>
								</Grid>
								<Divider orientation="vertical" flexItem />
								<Grid item>
									<Typography variant="caption" component="div">ASK</Typography>
									<Typography variant="h5">
										{quote.ask.toFixed(decimals).slice(0, -1)}
										<small><sup>{quote.ask.toFixed(decimals).slice(-1)}</sup></small>
									</Typography>
								</Grid>
							</Grid>
						</Box>
					)}
					{quote && service !== 'FOREX' && (
						<Box p={2}>
							<Typography variant="subtitle2">last</Typography>
							<Typography variant="h4" gutterBottom>{currency((quote.lastPrice || quote.price), true).toString()}</Typography>
							<Grid container spacing={2}>
								<Grid item xs>
									<Typography variant="subtitle2">bid</Typography>
									<strong>{currency(quote.bidPrice).toString()}</strong>
								</Grid>
								<Grid item xs>
									<Typography variant="subtitle2">mark</Typography>
									<strong>{currency(quote.mark || quote.price).toString()}</strong>
								</Grid>
								<Grid item xs>
									<Typography variant="subtitle2">ask</Typography>
									<strong>{currency(quote.askPrice).toString()}</strong>
								</Grid>
								{service === 'OPTION' && (
									<Grid item xs>
										<Typography variant="subtitle2">IV</Typography>
										{/*<strong>{quote.volatility && quote.volatility.toFixed(2)}</strong>*/}
										<strong>{quote.iv && quote.iv.toFixed(2)}</strong>
										{/*<Typography variant="subtitle2">Open Interest</Typography>
										<strong>{quote.openInterest}</strong>*/}
									</Grid>
								)}
							</Grid>

							{/*{service === 'OPTION' && (
								<Grid container spacing={2}>
									<Grid item>
										<Typography variant="subtitle2">volume</Typography>
										<strong>{quote.volume}</strong>
									</Grid>
									<Grid item>
										<Typography variant="subtitle2">OI</Typography>
										<strong>{quote.openInterest}</strong>
									</Grid>
								</Grid>
							)}*/}

							{service === 'OPTION' && (
								<Fragment>
									<Grid container spacing={2}>
										<Grid item xs>
											<Typography variant="subtitle2">delta</Typography>
											<strong>{quote.delta && quote.delta.toFixed(2)}</strong>
										</Grid>
										<Grid item xs>
											<Typography variant="subtitle2">gamma</Typography>
											<strong>{quote.gamma && quote.gamma.toFixed(3)}</strong>
										</Grid>
										<Grid item xs>
											<Typography variant="subtitle2">theta</Typography>
											<strong>{quote.theta && quote.theta.toFixed(2)}</strong>
										</Grid>
										<Grid item xs>
											<Typography variant="subtitle2">vega</Typography>
											<strong>{quote.vega && quote.vega.toFixed(2)}</strong>
										</Grid>
									</Grid>

								</Fragment>
							)}

							{service === 'LEVELONE_FUTURES' && (
								<Box>
									Notional value: {quote.multiplier * quote.last}
								</Box>
							)}
						</Box>
					)}
				</Card>
				{service === 'FOREX' && quote.timestamp && (
					<Typography variant="caption">
						Last Updated:&nbsp;
						<Moment parse="X" date={quote.timestamp} />
					</Typography>
				)}
			</Box>
		);

};

const mapStateToProps = (state, ownProps) => {
	const { service, symbol } = ownProps;
	let { quote } = ownProps;

	if (!quote) {
		quote = quoteSelectors.getQuote(state, { service, symbol });
	}

	return {
		quote,
	};
};

const mapDispatchToProps = {
	subscribe: socketOperations.subscribe,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTheme(QuoteCard));
