import { createSelector } from 'reselect';


const getChain = (state, { symbol, expiration }) => {
	let chain;
	if (symbol && state.chains) {
		chain = state.chains[symbol];
	}
	if (chain && expiration) {
		chain = chain[expiration];
	}
	return chain;
};

const getAvailableExpirationDates = createSelector(
	[
		getChain,
		(state, { strategy = 'SINGLE' }) => strategy
	],
	(chain, strategy) => {
		if (chain) {
			if (strategy && chain[strategy]) {
				const dates = Object.keys(chain[strategy].expirationDates);
				return dates;
			}
		}
		return [];
	}
);

const getAvailableSpreadIntervals = createSelector(
	[
		getChain,
		(state, { strategy = 'VERTICAL' }) => strategy
	],
	(chain, strategy) => {
		console.log('0');
		if (chain) {
			console.log('1', chain);
			if (strategy && chain[strategy]) {
				console.log('strategy', strategy);
				let intervals = chain[strategy].intervals;
				return intervals.filter(i => (i > 0 && i <= 10));
			}
		}
		return [];
	}
);

const getDefaultSpreadInterval = createSelector(
	[
		getChain,
		(state, { strategy = 'VERTICAL' }) => strategy
	],
	(chain, strategy) => {
		if (chain && chain[strategy]) {
			return chain[strategy].defaultInterval;
		}
		return null;
	}
);

const getSpreadIntervalCount = createSelector(
	[
		getChain,
		(state, { strategy = 'VERTICAL', expiration, interval }) => ({ strategy, expiration, interval }),
	],
	(chain, strategy, expiration, interval) => {
		if (
			chain && chain[strategy]
			&& chain[strategy].expirationDates[expiration]
			&& chain[strategy].expirationDates[expiration][interval]
		) {
			return chain[strategy].expirationDates[expiration][interval].length;
		}
		return null;
	}
);

export {
	getChain,
	getAvailableExpirationDates,
	getAvailableSpreadIntervals,
	getDefaultSpreadInterval,
	getSpreadIntervalCount,
}
