
const getQuotes = (state) => {
	return state.quotes;
};

const getQuote = (state, { service, symbol, agent }) => {
	if (state.quotes[service]) {
		return state.quotes[service][symbol];
	} else if (agent) {
		return state.agents[agent.id].agentData.quotes[symbol];
	}
};

const getSymbols = (state) => {
	return Object.keys(state.quotes);
};

export {
	getQuote,
	getQuotes,
	getSymbols,
};
