import { all, takeLatest, put, fork, select } from 'redux-saga/effects';

import { api } from '@@Utils';

import actions from './actions';
import types from './types';
import axios from 'axios';

/**
 Workers
 */
function* fetchTransactions() {
	const { auth } = yield select();
	const { session } = auth;

	try {
		const token = session.idToken.jwtToken;
		//const { data } = yield api.get(`/transactions`);
		const { data } = yield axios.get(`https://ietn8j98ib.execute-api.us-east-2.amazonaws.com/transactions`, {
			headers: {
				'Authorization': `Bearer ${token}`,
				'Content-Type': 'application/json',
			}
		});

		yield put(actions.getTransactions.success(data));
	}
	catch (err) {
		yield put(actions.getTransactions.failure(err));
	}
}

/**
 * Watchers
 */
function *watchTransactions() {
	try {
		yield takeLatest(types.TRANSACTIONS.REQUEST, fetchTransactions);
	} catch(err) {
		yield put(actions.getTransactions.failure(err));
	}
}

/**
 * Sagas
 */
function* sagas() {
	yield all([
		fork(watchTransactions),
	]);
}

export default sagas;
