import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { withTheme } from '@mui/styles';
import { Box, Card, CardHeader, CardContent, Typography } from '@mui/material';
import { selectors as activesSelectors } from '@@Redux/actives';
import DebugOutput from "@@Components/DebugOutput";
import * as authSelectors from "@@Redux/auth/selectors";

const Actives = (props) => {
	return (
		<Box mb={2}>
			<Card style={{ width: '100%' }}>
				<CardHeader
					title={(
						<Fragment>
							<Typography variant="h6">
								({props.symbol})
							</Typography>
						</Fragment>
					)}
				>
				</CardHeader>
				<CardContent>
					{props.actives.actives && props.actives.actives.map((active) => (
						<div key={active}>
							{active}
						</div>
					))}
					{/* <DebugOutput data={props.actives} label="actives" /> */}
				</CardContent>
			</Card>

		</Box>
	);
};

const mapStateToProps = (state, ownProps) => {
	return {
		actives: activesSelectors.getActives(state, ownProps.symbol),
		session: authSelectors.getSession(state),
	};
};

export default connect(
	mapStateToProps,
	{},
)(withTheme(Actives));

