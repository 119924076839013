import { all, call, delay, takeLatest, put, fork, select } from 'redux-saga/effects';
import {Auth} from 'aws-amplify';
// import { push, getLocation } from 'connected-react-router';

import actions from './actions';
import types from './types';
import {api} from "@@Utils";


/**
 Workers
 */
function* getChain(action) {
	console.info('[saga] getChain()');

	const { payload }  = action;
	const { symbol, expirationFrom, expirationTo, interval } = payload;

	let endpoint = `/chains/${symbol}?`;
	if (interval) {
		endpoint += `&interval=${interval}`;
	}
	if (expirationFrom) {
		endpoint += `&expirationFrom=${expirationFrom}`;
	}
	if (expirationTo) {
		endpoint += `&expirationTo=${expirationTo}`;
	}

	console.info('[saga] getChain()', endpoint);

	try {
		const { data } = yield api.get(endpoint);
		yield put(actions.getChain.success(data, payload));
	} catch (err) {
		yield put(actions.getChain.failure(err));
	}
}

/**
 * Watchers
 */
function *watchChain() {
	try {
		yield takeLatest(types.CHAIN.REQUEST, getChain);
	} catch(err) {
		yield put(actions.getChain.failure(err));
	}
}

/**
 * Sagas
 */
function* sagas() {
	yield all([
		fork(watchChain),
	]);
}

export default sagas;
