import React from 'react';
import { withTheme } from '@mui/styles';
import { Box, Button, Grid2 as Grid, Typography } from '@mui/material';

import AccountSummary from './AccountSummary';
import Actives from './Actives';
import { selectors as activesSelectors } from "@@Redux/actives";
import * as authSelectors from "@@Redux/auth/selectors";
import { connect } from "react-redux";
import { API, Auth } from 'aws-amplify';
import config from "@@Config";
import { getAWSCredentials } from "@@Redux/auth/operations";
import { api } from "@@Utils";
import DebugOutput from '@@Components/DebugOutput';

const Dashboard = (props) => {

	return (
		<Box my={2}>
			<Grid container spacing={2}>
				<Grid size={{ xs: 12, sm: 6 }}>
					<Typography variant="h4" gutterBottom>
						Actives
					</Typography>
					<Actives symbol="NASDAQ-60" />
					<Actives symbol="NYSE-60" />
					<Actives symbol="OPTS-DESC-ALL" />

					<DebugOutput data={props.session} label="session" />

					<Button onClick={async () => {

						const session = await Auth.currentSession();
						const token = session.idToken.jwtToken;

						// // TODO: Investigate use of axios request interceptors outside this file to avoid currentSession() calls
						// api.defaults.headers.Authorization = `Bearer ${token}`;

						// {"sub":"819fa7f5-e811-4080-b018-093353795677","aud":"6sk0fphssk6p8juhto2m6gauip","email_verified":true,"event_id":"90141f2f-4ee8-4c4d-aae9-4473d281fca9","token_use":"id","auth_time":1730554635,"iss":"https://cognito-idp.us-east-2.amazonaws.com/us-east-2_7zSkXIr1m","cognito:username":"819fa7f5-e811-4080-b018-093353795677","exp":1732038775,"iat":1732035175,"email":"jdewanko@gmail.com"}

						//"https://rsh34wwniol3d2hie2inzovqzq0xpwck.lambda-url.us-east-2.on.aws/", {
						fetch(


							'https://ietn8j98ib.execute-api.us-east-2.amazonaws.com/ht-hello', {
							method: "GET",
							headers: {
								'Authorization': `Bearer ${token}`,
								'Content-Type': 'application/json',
							},

						})
							.then((response) => response.json())
							.then((data) => {
								console.log(data);
							})
							.catch((error) => console.log(error));
						console.log('button');
						// try {
						// 	const result = await API.get('ht-api', '/ht-hello');
						// 	console.log(result);
						// } catch (error) {
						// 	console.error('Error invoking Lambda:', error);
						// }
						// const user = await Auth.currentAuthenticatedUser();
						// console.log(user);
						// const result = await API.post('ht-api', '/ht-hello', {
						// 	headers: {
						// 		Authorization: `Bearer ${user.signInUserSession.idToken.jwtToken}`
						// 	},
						// 	body: {
						// 		// Data to send to the Lambda function
						// 	}
						// });

						/*const credentials = await Auth.currentCredentials();
						const lambda = new AWS.Lambda({
							region: config.aws.region,
							credentials: Auth.essentialCredentials(credentials)
						});

						const params = {
							FunctionName: 'ht-hello',
							Payload: JSON.stringify({ /!* your payload *!/ })
						};

						// const response = await lambda.invoke(params).promise();
						// console.log('Lambda response:', response);*/
						// const credentials = await Auth.currentCredentials();
						// const lambda = new AWS.Lambda({
						// 	region: config.aws.region,
						// 	credentials: Auth.essentialCredentials(credentials)
						// });
						//
						// const session = await Auth.currentSession();
						// const idToken = session.getIdToken().getJwtToken();
						//
						// const params = {
						// 	FunctionName: 'ht-hello',
						// 	InvocationType: 'RequestResponse',
						// 	Payload: JSON.stringify({ userIdToken: idToken }),
						// };
						//
						// try {
						// 	const result = await lambda.invoke(params).promise();
						// 	console.log('Lambda Response:', JSON.parse(result.Payload));
						// } catch (error) {
						// 	console.error('Error invoking Lambda:', error);
						// }

						//
						// const token = props.session.token;
						//
						// const lambda = new AWS.Lambda({
						// 	region:config.aws.region,
						// 	credentials: new AWS.CognitoIdentityCredentials({
						// 		IdentityPoolId: config.aws.cognito.identityPool,
						// 		Logins: {
						// 			[`cognito-idp.${config.aws.region}.amazonaws.com/${config.aws.cognito.userPoolId}`]: token,
						// 		},
						// 	}),
						// });

						// const result = await lambda.invoke({
						// 	FunctionName: 'ht-hello'
						// }).promise();
						// console.log('Lambda result:', JSON.parse(result.Payload));

					}}>
						Click me
					</Button>
				</Grid>
				<Grid size={{ xs: 12, sm: 6 }}>
					<Typography variant="h4" gutterBottom>
						Account Summary
					</Typography>
					<AccountSummary />
				</Grid>
			</Grid>
		</Box>
	);
};


const mapStateToProps = (state, ownProps) => {
	return {
		session: authSelectors.getSession(state),
	};
};

export default connect(
	mapStateToProps,
	{},
)(withTheme(Dashboard));
