import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { Form, Formik } from 'formik';
import { v4 as uuid } from 'uuid';
import moment from 'moment';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/theme-tomorrow';
import { withTheme } from '@mui/styles';
import {
	Box,
	Button,
	Checkbox,
	FormControlLabel,
	FormHelperText,
	Grid2 as Grid,
	TextField,
	Dialog,
	DialogContent,
	DialogActions,
	Divider,
	InputAdornment,
	InputLabel,
	MenuItem,
	Typography,
} from '@mui/material';

import SpreadIntervals from '@@Components/Options/Agents/SpreadIntervals';
import ExpirationDates from '@@Components/Options/Agents/ExpirationDates';
import DialogTitle from '@@Components/Dialog/DialogTitle/DialogTitle';
import { selectors as accountSelectors } from '@@Redux/account';
import { operations as agentOperations } from '@@Redux/agents';
import { operations as chainOperations, selectors as chainSelectors } from '@@Redux/chains';
import { operations as socketOperations } from '@@Redux/socket';
import * as Styles from '@@Components/Dialog/DialogTitle/DialogTitle.styles';
import CloseIcon from '@mui/icons-material/Close';


const AgentDialog = ({
	agent,
	buyingPower,
	createAgent,
	getChain,
	intervalCount,
	isOpen,
	onClose,
	updateAgent,
	subscribe,
}) => {

	const getInitialValues = () => {

		let allocatedFunds = Math.floor(Math.min(buyingPower, 100));

		return {
			name: agent ? agent.name : '',
			allocatedFunds,
			underlyingSymbol: '',
			buyCondition: 'return true;',
			sellCondition: '',
		};
	};

	const handleSubmit = (values) => {
		const underlying = values.underlyingSymbol.toUpperCase();
		const agentObj = {};
		if (agent && agent.id) {
			agentObj.id = agent.id;
			// updateAgent(agent);
		} else {
			agentObj.id = uuid();
			agentObj.config = {
				type: 'OPTION',
				name: values.name || '',
			};

			console.log('agent', agentObj);

			createAgent(agentObj);
			// subscribe({ service: 'QUOTE', symbol: underlying });
		}
		onClose();
	};

	return (
		<div>
			<Dialog
				open={isOpen}
				onClose={onClose}
				aria-labelledby="dialog-title"
				fullWidth
				maxWidth="sm"
			>
				<DialogTitle id="dialog-title">
					Add Wheel Agent
				</DialogTitle>

				{onClose && (
					<Styles.CloseButton aria-label="close" onClick={onClose}>
						<CloseIcon />
					</Styles.CloseButton>
				)}

				<DialogContent>

					<Formik
						initialValues={getInitialValues()}
						enableReinitialize={true}
						onSubmit={handleSubmit}
					>
						{({
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							isSubmitting,
							setFieldValue
						}) => (
							<Form>
								<DialogContent>

									{/* <Grid container spacing={2}>
										<Grid item xs={4}>
											<TextField
												fullWidth
												id="allocatedFunds"
												name="allocatedFunds"
												label="Allocated Funds"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.allocatedFunds}
												type="number"
												helperText={`${Math.round((values.allocatedFunds / buyingPower) * 100)}% of available funds`}
												inputProps={{ maxLength: 4 }}
												InputProps={{
													startAdornment: (
														<InputAdornment position="start">$</InputAdornment>
													),
												}}
											/>
										</Grid>
									</Grid> */}

									<Grid container spacing={2} alignItems="flex-end">
										<Grid size={{ xs: 4 }}>
											<TextField
												fullWidth
												id="underlyingSymbol"
												name="underlyingSymbol"
												label="Underlying Symbol"
												placeholder="AAPL"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.underlyingSymbol.toUpperCase()}
												inputProps={{maxLength: 4}}
												InputLabelProps={{
													shrink: true,
												}}
												variant="filled"
											/>
										</Grid>

										<Grid size={12}>
											<TextField
												fullWidth
												id="name"
												name="name"
												variant="filled"
												label="Agent Name"
												onBlur={handleBlur}
												onChange={handleChange}
												value={values.name}
											/>
										</Grid>
									</Grid>

								</DialogContent>

								<DialogActions>
									<Button
										type="submit"
										variant="contained"
										color="primary"
										disabled={!values.underlyingSymbol}
									>
										Save Agent
									</Button>
								</DialogActions>

							</Form>
						)}
					</Formik>
				</DialogContent>
			</Dialog>
		</div>
	);
};

const mapStateToProps = (state) => {
	return {
		buyingPower: accountSelectors.getBuyingPower(state),
	};
};

const mapDispatchToProps = {
	createAgent: agentOperations.createAgent,
	getChain: chainOperations.getChain,
	subscribe: socketOperations.subscribe,
};

export default connect(
	mapStateToProps,
	mapDispatchToProps,
)(withTheme(AgentDialog));
