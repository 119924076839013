
const getTransactions = (state, { symbol, startDate, endDate }) => {
	let transactions = Object.values(state.transactions) || [];

	if (symbol) {
		transactions = transactions.filter((transaction) => {
			if (transaction.instrument) {
				return transaction.instrument.symbol === symbol;
			}
		});
	}
	return transactions;
};

const getTransaction = (state, { transactionId }) => {
	return state.transactions[transactionId];
};

export {
	getTransactions,
	getTransaction,
}
