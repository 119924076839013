import styled, { css } from 'styled-components';

import { DialogTitle, IconButton } from '@mui/material';
import { withTheme } from '@mui/styles';

const StyledDialogTitle = withTheme(styled(DialogTitle)`
	margin: 0;
	padding: ${({ theme }) => theme.spacing(2)};
	font-weight: 600;
`);

const CloseButton = withTheme(styled(IconButton)`
	position: absolute;

	${({ theme }) => {
		return css`
			right: ${theme.spacing(1)};
			top: ${theme.spacing(1)};
			color: ${theme.palette.grey[500]};
		`;
	}}
`);

export {
	StyledDialogTitle as DialogTitle,
	CloseButton,
};

// Display Names
StyledDialogTitle.displayName = 'StyledDialogTitle';
CloseButton.displayName = 'CloseButton';
