import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import { fork, all } from 'redux-saga/effects';
// import { connectRouter, routerMiddleware } from 'connected-react-router';
import { createBrowserHistory } from 'history';

import account, { saga as accountSaga } from '@@Redux/account';
import actives from '@@Redux/actives';
import agents, { saga as agentsSaga } from '@@Redux/agents';
import apiStatus from '@@Redux/apiStatus';
import auth, { saga as authSaga }  from '@@Redux/auth';
import backtest from '@@Redux/backtest';
import chains, { saga as chainsSaga } from '@@Redux/chains';
import charts from '@@Redux/charts';
import dataset from '@@Redux/dataset';
import orders, { saga as ordersSaga } from '@@Redux/orders';
import middleware, { sagaMiddleware } from '@@Redux/middleware';
import positions, { saga as positionsSaga } from '@@Redux/positions';
import quotes from '@@Redux/quotes';
import socket from '@@Redux/socket';
import transactions, { saga as transactionsSaga } from '@@Redux/transactions';


// noinspection JSUnresolvedVariable
const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = (history) => combineReducers({
	// router: connectRouter(history),
	apiStatus,
	account,
	// actives,
	agents,
	auth,
	// backtest,
	chains,
	// charts,
	// dataset,
	orders,
	positions,
	quotes,
	socket,
	transactions,
});

function* rootSaga() {
	yield all([
		fork(accountSaga),
		fork(agentsSaga),
		fork(authSaga),
		fork(chainsSaga),
		fork(ordersSaga),
		fork(positionsSaga),
		fork(transactionsSaga)
	])
}

const appState = {};
export const history = createBrowserHistory();

const configureStore = (/* preloadedState */) => {
	const store = createStore(
		rootReducer(history),
		appState,
		composeEnhancer(
			applyMiddleware(
				// routerMiddleware(history),
				...middleware,
			),
		),
	);

	sagaMiddleware.run(rootSaga);

	return store;
};

export default configureStore;
