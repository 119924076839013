import { produce } from 'immer';

import types from './types';


const orders = (state = {}, action) => produce(state, draft => {
	switch (action.type) {
		case types.ORDERS.SUCCESS: {
			if (action.payload) {
				return action.payload;
			}
			return;
		}
		case types.ORDER.SUCCESS:
		case types.ORDER_CREATE.SUCCESS: {
			const { orderId } = action.payload;
			draft[orderId] = action.payload;
			return;
		}
		default: return state;
	}
});

const reducer = orders;

export default reducer;

