import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router';
import { Provider } from 'react-redux';

import { configureAmplify } from '@@/aws.config';
import App from '@@Components/App';
import ErrorBoundary from '@@Components/ErrorBoundary';
import configureStore, { history } from '@@Redux/store';

configureAmplify();

const domNode = document.getElementById('root');
const root = createRoot(domNode);

root.render(
	<ErrorBoundary>
		<Provider store={configureStore()}>
			<BrowserRouter history={history}>
				<App />
			</BrowserRouter>
		</Provider>
	</ErrorBoundary>
);
