import { produce } from 'immer';

import types from './types';


const transactions = (state = {}, action) => produce(state, draft => {
	switch (action.type) {
		case types.TRANSACTIONS.SUCCESS: {
			if (action.payload) {
				return action.payload;
			}
			return;
		}
		case types.TRANSACTION.SUCCESS: {
			const { transactionId } = action.payload;
			draft[transactionId] = action.payload;
			return;
		}
		default: return state;
	}
});

const reducer = transactions;

export default reducer;

