import React from 'react';
import PropTypes from 'prop-types';

import { Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

import * as Styles from './DialogTitle.styles';

const propTypes = {};

const DialogTitle = ({
	children,
	...otherProps
}) => {
	return (

		<Styles.DialogTitle {...otherProps}>
			{children}
		</Styles.DialogTitle>

	)
};

DialogTitle.propTypes = propTypes;

export default DialogTitle;
