const getAgents = (state, { type }) => {
	let agents = Object.values(state.agents) || [];

	// if (type) {
	// 	agents = agents.filter((agent) => agent.config.type === type);
	// }

	return agents;
};

const getAgent = (state, id) => {
	return state.agents[id];
};

const getAgentMessages = (state) => {
	return state.socket.messages || [];
};

export {
	getAgents,
	getAgent,
	getAgentMessages,
}
