import React, { Fragment } from 'react';
import { Route, Routes, Link } from 'react-router';
import { Grid2 as Grid, Typography } from '@mui/material';

import Orders from '@@Components/Options/Orders/Orders';
import PositionsList from '@@Components/Options/Positions/PositionsList';
import Transactions from '@@Components/Options/Transactions/Transactions';
import Dashboard from '@@Components/Options/Dashboard/Dashboard';
import AgentDashboard from "@@Components/Options/Agents/AgentDashboard";


const Options = (props) => {
	return (
		<Fragment>
			{/*<Typography variant="h5" gutterBottom>Options</Typography>*/}

			<Grid container spacing={2} alignContent="center" justifyContent="center">
				<Grid>
					<Link to="/options/dashboard">Dashboard</Link>
				</Grid>
				<Grid>
					<Link to="/options/orders">Orders</Link>
				</Grid>
				<Grid>
					<Link to="/options/positions">Positions</Link>
				</Grid>
				<Grid>
					<Link to="/options/transactions">Transactions</Link>
				</Grid>
				<Grid>
					<Link to="/options/agents-dashboard">Agents</Link>
				</Grid>
			</Grid>
			<Grid container spacing={2}>
				<Grid>
					<Routes>
						<Route path="/" element={<Dashboard />} />
						<Route path='/dashboard' element={<Dashboard />} />
						<Route path='/orders' element={<Orders />} />
						<Route path='/positions' element={<PositionsList />} />
						<Route path='/transactions' element={<Transactions />} />
						<Route path="/agents-dashboard" element={<AgentDashboard />} />
					</Routes>
				</Grid>
			</Grid>

		</Fragment>
	);
};

export default Options;
