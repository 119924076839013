import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withTheme } from '@mui/styles';
import { MenuItem, TextField } from '@mui/material';

import { selectors as chainSelectors } from '@@Redux/chains';


const ExpirationDates = ({
	expirationDate,
	expirationDates,
	onChange,
	symbol,
	label = 'Expiration Date'

}) => {

	useEffect(() => {
		onChange('');
	}, [symbol]);

	const handleChange = (e) => {
		const expirationDate = e.target.value;
		onChange(expirationDate);
	};

	if (expirationDates.length > 0) {
		return (
			<TextField
				fullWidth
				select
				label={label}
				id="expirationDate"
				value={expirationDate}
				onChange={handleChange}
				variant="filled"
			>
				{expirationDates.map((date) => (
					<MenuItem
						key={date}
						value={date.split(':')[0]}
					>
						{date.split(':')[0]}&nbsp;&nbsp;<span style={{ color: '#999', fontSize: '0.75em' }}>[{date.split(':')[1]}]</span>

					</MenuItem>
				))}
			</TextField>
		);
	}
	return null;
};


const mapStateToProps = (state, ownProps) => {
	return {
		expirationDates: chainSelectors.getAvailableExpirationDates(state, { symbol: ownProps.symbol }),
	}
};

export default connect(
	mapStateToProps,
)(withTheme(ExpirationDates));

